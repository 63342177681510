import store from '../store/index.js'

export const API_DOMAIN = getDomain();
export const Promotion_Brand = "The best space of “D” Brand Experience Center VOTE EVENT";
export const Promotion_Ondoll = "Find the best pet (On-doll) EVENT";

function getDomain() {
  if(process.env.VUE_APP_BUILD_MODE == 'DEV'){
    return 'https://api.dvision-web-dev.com:3000'
  }else if(process.env.VUE_APP_BUILD_MODE == 'LOC'){
    return 'http://localhost:12345'
  }else{
    return 'https://api.dvision-web.com:3000'
  }
}

/**
 * 프로모션 투표 옵션
 * @param {*} type 
 * @param {*} title 
 * @returns array
 * 0: 1-> 이미지 한장, 2-> 리스트형
 * 1: 투표 선택지 array
 */
export function votingOption(type, title) {
  if(type !== "promotion") {
    return [0, ["Agree", "Disagree"]];
  } else {
    switch(title) {
      case Promotion_Brand:
        return [1, ['#1 PHOTO ZONE', '#2 MAZE ZONE', '#3 D CAFÉ', '#4 ON-DOLL ZONE', '#5 THEATER ZONE', '#6 BATTLE ZONE', '#7 ON-DOLL ZONE', '#8 GALLERY ZONE', '#9 GOODS ZONE']];
      case Promotion_Ondoll:
        return [2, optionLang(['whiteball_6', 'bulldog_5', 'knight_7', 'cat_4', 'laser_6', 'whale_6', 'octo_4', 'angel_8', 'panda_5', 'yellow_8'])]
    }
  }
}

function optionLang(arr) {
  return arr.map((v, i) => `#${i + 1} ${store.state.languageData[v].languageEn}`)
}