<template>
	<div class="GNB" :theme="theme ? theme: 'black'" :signed="signed" :class="isShowNavbar?'shadow':''">
		<img src="../assets/img/btn-menu-2.svg" v-if="!isShowNavbar" class="button-nav" @click="showNavbar" />
		<img src="../assets/img/btn-menu-close.svg" v-else class="button-nav" @click="showNavbar" />
		<div class="gnb-box-bg"></div>
		<div class="gnb-box" :class="isShowNavbar ? 'active' : ''">
			<div class="menu-box">
				<router-link class="logo remove-highlight" :to="{ params: {lang:'en'}, name:'Home'}"></router-link>
				<div class="menus">
					<router-link @click.enter="clickMarketPlace()" class="menu remove-highlight" :active="(currentPage=='Market' || currentPage=='Market-Detail' || currentPage=='Market-Page' || currentPage=='Market-Detail-Index' ? 'on' : 'off')" :to="{ params: {lang:'en'}, name:'Market'}"> {{$t("gnb.market_place")}} </router-link>
					<a @click="isShowNavbar=false" class="menu" href="https://dvision-bridge.multibaas.app/" target="_blank">{{$t("gnb.bridge")}}</a>
					<!-- <router-link @click="isShowNavbar=false" class="menu remove-highlight" :active="(currentPage=='Studio' ? 'on' : 'off')" :to="{ params: {lang:'en'}, name:'Studio'}"> {{$t("gnb.studio")}} </router-link> -->
					<router-link @click="isShowNavbar=false" class="menu remove-highlight" :active="(currentPage=='Guide-Page' ? 'on' : 'off')" :to="{ params: {lang:'en'}, name:'Guide'}"> {{$t("gnb.guide")}} </router-link>
					<router-link @click="isShowNavbar=false" class="menu remove-highlight" :active="(currentPage=='Dao' ? 'on' : 'off')" :to="{ params: {lang:'en'}, name:'Dao'}"> {{$t("gnb.dao")}} </router-link>
					<router-link @click="isShowNavbar=false" class="menu remove-highlight" :active="(currentPage=='Event' ? 'on' : 'off')" :to="{ params: {lang:'en'}, name:'Event'}"> {{$t("gnb.event")}} </router-link>
					<router-link @click="isShowNavbar=false" class="menu remove-highlight" :active="(currentPage=='News-Page' || currentPage=='News-Detail' ? 'on' : 'off')" :to="{ params: {lang:'en'}, name:'News'}"> {{$t("gnb.news")}} </router-link>
					<div v-if="signed=='on'" class="info-network-mobile"
							@click="showNetwork = !showNetwork"
						>
							<div class="network-icon">
								<span id="network-name">{{$t('gnb.network')}}</span>
							</div>
							<transition appear name="fade">
								<div class="network-popup" v-if="showNetwork">
									<div
										class="network-wrapper"
										@click="onClickItem('ETH')"
									>
										<img
											class="icon"
											src="../assets/img/ethereum.png"
											alt="icon"
										/>
										<input
											type="checkbox"
											id="Ethereum"
											value="Ethereum"
											:checked="checkedNetwork === 'Ethereum'"
										/>
										<label for="etherium">Ethereum</label>
									</div>
									<div
										class="network-wrapper"
										@click="onClickItem('BSC')"
										v-if="loginBy !== 'BITSKI'"
									>
										<img
											class="icon"
											src="../assets/img/bsc.svg"
											alt="icon"
										/>
										<input
											type="checkbox"
											id="BSC"
											value="BSC"
											:checked="checkedNetwork === 'BSC'"
										/>
										<label for="bsc">BSC</label>
									</div>
									<div
										class="network-wrapper"
										@click="onClickItem('POL')"
									>
										<img
											class="icon"
											src="../assets/img/polygon.svg"
											alt="icon"
										/>
										<input
											type="checkbox"
											id="Polygon"
											value="Polygon"
											:checked="checkedNetwork === 'Polygon'"
										/>
										<label for="Polygon">Polygon</label>
									</div>
								</div>
							</transition>
					</div>
					<!-- <router-link class="menu" :active="(currentPage=='Help' ? 'on' : 'off')" :to="{ params: {lang:'en'}, name:'Help'}"> {{$t("gnb.help")}} </router-link> -->
					<div :class="isShowNavbar?'menu-btns-mobile':'hidden'">
						<div class="dvs-nav-item">
							<div class="dvg-coin">
								<img src="../assets/img/ic-dvi-market.svg" alt="">
								{{addComma(wallet.balance)}}
							</div>
								<div v-if="balanceWallet" class="wallet-balance">
							<div class="icon" :class="{'pol-icon': checkedNetwork === 'Polygon', 'eth-icon': checkedNetwork === 'Ethereum', 'bnb-icon': checkedNetwork === 'BSC'}"></div>
							{{ addComma(balanceWallet) }}
						</div>
						</div>
							<div class="dvs-nav-item" > 
							<div class="dvs-user" v-if="signed=='on'">
								<!-- <div class="uinfo-icon">
									<span class="cap-text">{{ getCapitalChar(userInfo.name) }}</span>
								</div> -->
								<div class="user-menu mypage" @click="goMyPage('MyPage')">{{$t('gnb.mypage')}}</div>
							</div>
							<div v-else class="dvs-no-user">
								<span class="menu text remove-highlight" @click="login">{{ $t("gnb.login")}}</span>
								<router-link @click="isShowNavbar=false" class="menu text signup remove-highlight" :active="(currentPage=='Signup' || currentPage=='Signup-Page' ? 'on' : 'off')" :to="{ params: {lang:'en'}, name:'Signup'}"> {{$t("gnb.signup")}} </router-link>
							</div>
							<button class="btn g-btn" :class="{'no-login-btn': signed != 'on'}" @click="startNow">{{$t("gnb.start_now")}}</button>
						</div>
						<div class="dvs-nav-item">
							<!-- <div class="menu text remove-highlight" @click="isShowLangMenu=!isShowLangMenu">
								<span class="lang-text">{{ $t("gnb.language")}}</span>
								<transition appear name="fade">
									<div v-if="isShowLangMenu" class="user-menu-popup-wrap lang">
											<div class="list-lang">
												<div @click_xx="$emit('change-locale', { lang: 'en' })">{{$t('gnb.language-en')}}</div>
											</div>
									</div>
								</transition>
							</div> -->
							<div v-if="signed=='on'" class="user-menu logout" @click="logout()">{{$t('gnb.logout')}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="menu-btns">
				<div class="menus">
					<!-- <div class="menu text" @mouseover="isShowLangMenu=true" @mouseleave="isShowLangMenu=false">
						<span class="lang-text">{{ $t("gnb.language")}}</span>
						<transition appear name="fade">
							<div v-if="isShowLangMenu" class="user-menu-popup-wrap lang">
								<div class="user-menu-popup">
									<div class="user-menu lang" @click_xx="$emit('change-locale', { lang: 'en' })">{{$t('gnb.language-en')}}</div>
								</div>
							</div>
						</transition>
					</div> -->

					<template v-if="signed=='on'">
						<!-- <span v-if="network=='POL'" class="menu pol-box">
							<span class="pol-icon"></span>
							<span class="pol-value">{{addComma(wallet.polygonBalance)}}</span>
						</span> -->
						<div class="info-network"
							@click="showNetwork = !showNetwork"
						>
							<div class="network-icon">
								<img id="network-name"
									src="../assets/img/icon/network.png"
									alt="icon"
								/>
							</div>
							<transition appear name="fade">
								<div class="network-popup" v-if="showNetwork">
									<div
										class="network-wrapper"
										@click="onClickItem('ETH')"
									>
										<img
											class="icon"
											src="../assets/img/ethereum.png"
											alt="icon"
										/>
										<input
											type="checkbox"
											id="Ethereum"
											value="Ethereum"
											:checked="checkedNetwork === 'Ethereum'"
										/>
										<label for="etherium">Ethereum</label>
									</div>
									<div
										class="network-wrapper"
										@click="onClickItem('BSC')"
										v-if="loginBy !== 'BITSKI'"
									>
										<img
											class="icon"
											src="../assets/img/bsc.svg"
											alt="icon"
										/>
										<input
											type="checkbox"
											id="BSC"
											value="BSC"
											:checked="checkedNetwork === 'BSC'"
										/>
										<label for="bsc">BSC</label>
									</div>
									<div
										class="network-wrapper"
										@click="onClickItem('POL')"
									>
										<img
											class="icon"
											src="../assets/img/polygon.svg"
											alt="icon"
										/>
										<input
											type="checkbox"
											id="Polygon"
											value="Polygon"
											:checked="checkedNetwork === 'Polygon'"
										/>
										<label for="Polygon">Polygon</label>
									</div>
								</div>
							</transition>
						</div>
						<span class="menu dvi-box">
							<span class="dvi-icon"></span>
							<span class="dvi-value">{{addComma(wallet.balance)}}</span>
						</span>
						<div v-if="balanceWallet" class="wallet-balance">
							<div class="icon" :class="{'pol-icon': checkedNetwork === 'Polygon', 'eth-icon': checkedNetwork === 'Ethereum', 'bnb-icon': checkedNetwork === 'BSC'}"></div>
							{{ addComma(balanceWallet) }}
						</div>
						<div class="menu uinfo-icon" @mouseover="isShowAccountMenu=true" @mouseleave="isShowAccountMenu=false" >
							<img
								class="icon"
								src="../assets/img/icon/profile.png"
								alt="icon"
							/>
							<transition appear name="fade">
								<div v-if="isShowAccountMenu" class="user-menu-popup-wrap dvi">
									<div class="user-menu-popup">
										<div class="user-menu mypage" @click="goMyPage('MyPage')">{{$t('gnb.profile')}}</div>
										<div class="user-menu mypage" @click="goMyPage('inventory')">{{$t('gnb.inventory')}}</div>
										<div class="user-menu mypage" @click="goMyPage('land')">{{$t('gnb.land')}}</div>
										<div class="user-menu mypage" @click="goMyPage('staking')">{{$t('gnb.staking')}}</div>
										<div class="user-menu logout" @click="logout()">{{$t('gnb.logout')}}</div>
									</div>
								</div>
							</transition>
						</div>						
					</template>

					<template v-else>
						<router-link class="menu text" :active="(currentPage=='Signup' || currentPage=='Signup-Page' ? 'on' : 'off')" :to="{ params: {lang:'en'}, name:'Signup'}"> {{$t("gnb.signup")}} </router-link>
						<span class="menu text" @click="login">{{ $t("gnb.login")}}</span>
					</template>

					<button class="menu btn g-btn" @click="startNow">{{$t("gnb.start_now")}}</button>
				</div>
			</div>
		</div>		
		<div v-if="supportMobileMenu" class="menus-mobile" @click="showMobileMenu">
			<div class="menu">
				<div class="bars">
					<span class="bar bar1"></span>
					<span class="bar bar2"></span>
					<span class="bar bar3"></span>
				</div>
			</div>
		</div>
	</div>
	<div class="gnb-timer" v-if="signed=='on' && isShowTimer && isEther">
		<div class="gnb-timer-div">
			<img
				id="timer-icon"
				class="timer"
				:src="statusCampain == 3? require('../assets/img/icon/staking_time_btn.png') : require('../assets/img/icon/staking_time_btn_hover.png')"
				@click="showTimerType = !showTimerType"
			/>
			<transition appear name="fade">
				<div v-if="showTimerType" class="user-menu-popup-wrap dvi">
					<div class="user-menu-popup">
						<div class="user-menu mypage" @click="campaignChange(1)">30 DAYS</div>
						<div class="user-menu mypage" @click="campaignChange(2)">90 DAYS</div>
						<div class="user-menu mypage" @click="campaignChange(3)">180 DAYS</div>
					</div>
				</div>
			</transition>
			<div class="timer-span">
				<span :class="statusCampain == 3? 'on-going' : 'reward'" v-if="poolDuration.id == 1">[30DAYS]</span>
				<span :class="statusCampain == 3? 'on-going' : 'reward'" v-else-if="poolDuration.id == 2">[90DAYS]</span>
				<span :class="statusCampain == 3? 'on-going' : 'reward'" v-else-if="poolDuration.id == 3">[180DAYS]</span>
				<div class="timer-span">
					{{ days }} DAY&nbsp;&nbsp;&nbsp;{{ hours }} HRS&nbsp;&nbsp;&nbsp;{{ mins }} MIN
				</div>
			</div>
		</div>		
	</div>
</template>

<script>

import AppConfig from '@/App.Config.js'
import { ETH_RPC_ENDPOINT,BSC_RPC_ENDPOINT, MATIC_RPC_ENDPOINT,
	BSC_RPC_ENDPOINT_PROD, ETH_RPC_ENDPOINT_PROD, MATIC_RPC_ENDPOINT_PROD,
	WALLETCONNECT, checkProviderWallet, formatChainId,
	FORTMATIC, COINBASE, METAMASK, BITSKI } from '@/features/Common.js'
import { fortmaticProvider, bitski, walletConnectProvider } from '@/features/Connectors.js'
import { MSG_METAMASK_2, MSG_METAMASK_1 } from '@/features/Messages.js'
import { getContractConnect } from '@/features/Connectors.js'
import ABI_STAKING from '@/abi/DvisionStakingUpgradeable.json'
import moment from 'moment'

var gConfig = AppConfig();

import LogType from '@/features/LogType.js';

export default {
	props: {
		theme: {
			type: String,
		},
		userInfo: {
			type: Object,
		},
		wallet: {
			type: Object,
		}

	},
	data() {
		return {
			isShowAccountMenu: false,
			isShowLangMenu: false,
			supportMobileMenu: false,
			isShowNavbar: false,
			showNetwork: false,
			showTimerType: false,			
			checkedNetwork: 'Ethereum',
			chainId: 0,
			loginBy: null,
			isShowTimer: false,
			isEther: false,
			timeCount: {
				startValue: 0,
				endValue: 0,
			},
			poolDuration: {
				id: 1,
				duration: 0,
			},
			statusCampain: 1,
			staking_address: '',
			days: '00',
			hours: '00',
			mins: '00',
			interval_1: null,
			interval_2: null,
			interval_3: null,
		}
	},
	updated() {
		this.isShowTimer = (this.$route.fullPath == "/en/home");
	},
	computed: {
		signed() {
			this.checkCurrentNetwork()
			const loginBy = window.localStorage.getItem('loginBy')
			if (loginBy === WALLETCONNECT) {
				setInterval(() => {
					this.getInterval()
				}, 3000);
			}
			
			if(loginBy != null) {
				this.initLoginBy();
			}
			return this.userInfo.id ? 'on' : 'off';
		},
		network() {
			return this.$store.state.network;
		},
		currentPage() {
			return this.$route.name;
		},
		balanceWallet() {
			return this.$store.state.wallet.balanceWallet
		}
	},
	mounted () {
		// console.log("======== route params", this.$route, this.$route.params);
		this.currentPage = this.$route.params.id
		this.handlePopupNetwork()
	},
	methods: {
		onClickItem(network) {
			const loginBy = window.localStorage.getItem('loginBy')
			if (loginBy === COINBASE) {
				this.mxShowToast('Please change network on extension or mobile app, thank you.')
				return;
			}
			if(gConfig.isProd) {
				switch (network) {
					case 'ETH':
						this.switchNetwork('0x1', 'Ethereum', ETH_RPC_ENDPOINT_PROD)
						break
					case 'BSC':
						this.switchNetwork('0x38', 'BSC', BSC_RPC_ENDPOINT_PROD)
						break
					case 'POL':
						this.switchNetwork('0x89', 'Polygon', MATIC_RPC_ENDPOINT_PROD)
						break
				}
			} else {
				switch (network) {
					case 'ETH':
						this.switchNetwork('0x5', 'Ethereum', ETH_RPC_ENDPOINT)
						break
					case 'BSC':
						this.switchNetwork('0x61', 'BSC', BSC_RPC_ENDPOINT)
						break
					case 'POL':
						this.switchNetwork('0x13881', 'Polygon', MATIC_RPC_ENDPOINT)
						break
				}
			}
		},
		async switchNetwork(chainId, name, rpc) {
			const loginBy = window.localStorage.getItem('loginBy')
			try {
				if(loginBy === BITSKI) {
					window.localStorage.setItem('networkRPC', rpc)
					window.localStorage.setItem('fortmaticNetwork', chainId)
					window.localStorage.setItem('currentNetwork', chainId)
					window.location.reload()
				} else if(loginBy === FORTMATIC) {
					if(name !== 'Ethereum') {
					this.mxShowToast('Formatic only supports Ethereum network')
					}
				} else if(loginBy === METAMASK || loginBy === COINBASE) {
					const provider = checkProviderWallet(loginBy);
					await window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: chainId }],
					})
					this.checkedNetwork = name
				}
				else if(loginBy === WALLETCONNECT) {
					this.mxShowToast('Please change network on extension or mobile app, thank you')
				}

			} catch (e) {
				console.log('e',e)
				this.mxShowToast(e.message)
			}
		},
		async checkCurrentNetwork() {
			let currentNetwork = window.localStorage.getItem('currentNetwork')
			const loginBy = window.localStorage.getItem('loginBy')
			if(loginBy === FORTMATIC) {
				currentNetwork = window.localStorage.getItem('fortmaticNetwork')
			}
			if(loginBy === WALLETCONNECT) {
				const walletConnect = window.localStorage.getItem('walletconnect')
				this.chainId = JSON.parse(walletConnect).chainId
				currentNetwork = this.chainId.toString()
			}
			try {
				switch (currentNetwork) {
					case '0x5':
					case '5':
					case '0x1':
					case '1':
						this.checkedNetwork = 'Ethereum'
						break
					case '0x61':
					case '97':
					case '0x38':
					case '56':
						this.checkedNetwork = 'BSC'
						break
					case '0x13881':
					case '80001':
					case '0x89':
					case '137':
						this.checkedNetwork = 'Polygon'
						break
				}
			} catch (err) {
				console.log('err', err)
			}
		},
		getInterval() {
			const walletConnect = window.localStorage.getItem('walletconnect')
			const wc = JSON.parse(walletConnect)
			const chainId = wc.chainId
			const accounts = wc.accounts
			if(accounts[0].toLowerCase() !== this.$store?.state?.userInfo?.wallet_addr.toLowerCase()) {
				this.mxShowToast(MSG_METAMASK_1)
			}
			if(chainId !== this.chainId) {
				switch (chainId.toString()) {
					case '0x4':
					case '4':
					case '0x1':
					case '1':
						this.checkedNetwork = 'Ethereum'
						window.location.reload()
						break
					case '0x61':
					case '97':
					case '0x38':
					case '56':
						this.checkedNetwork = 'BSC'
						window.location.reload()
						break
					case '0x13881':
					case '80001':
					case '0x89':
					case '137':
						this.checkedNetwork = 'Polygon'
						window.location.reload()
						break
					default :
					this.mxShowToast(MSG_METAMASK_2)
					break
				}
			}
		},
		checkStyleOverflow() {
			const content = document.getElementById('content');
			content.style.overflowY = this.isShowNavbar ? 'hidden' : 'auto';
		},
		showNavbar() {
			this.isShowNavbar = !this.isShowNavbar;
		},
		showLanguage() {
			this.isShowNavbar = false
			console.log('show Language')
		},
		login() {
			this.isShowNavbar = false
			this.$store.dispatch('showLoginPopup',true);
			console.log('show Auth')
		},
		startNow() {
			//this.mxShowToast(this.$t('popup.app-construction-alert'));
			this.mxGameStart();
			this.isShowNavbar = false
		},
		showMobileMenu() {
			console.log('showMobileMenu()');
		},
		getCapitalChar(str) {
			return _U.getCapitalChar(str);
		},
		goMyPage(type) {
			this.isShowAccountMenu = false;
			if(type == "MyPage") {
				this.$router.push({name:type});
			}
			else {
				this.$router.push({name:"MyPage-Page", params:{'page': type}});
			}
			this.isShowNavbar = false
		},
		
		// eslint-disable-next-line no-dupe-keys
		logout() {
			this.clearWallet()
			window.localStorage.clear();
			this.mxSetWallet({});
			this.$router.push({name:"Home"});
			var userInfo = {}
			this.isShowNavbar = false
			this.isShowAccountMenu = false;
			this.$store.dispatch('setUserInfo',userInfo);
			this.$cookies.set('userInfo', userInfo, gConfig.getUserInfoCookieExpireTime());
			this.$store.dispatch('logout')
		
			setTimeout(() => {
				window.location.reload()
			}, 200);
		},
		clearWallet() {
			const loginBy = window.localStorage.getItem('loginBy');
			switch(loginBy) {
				case FORTMATIC :
					fortmaticProvider.user.logout()
					break
				case WALLETCONNECT :
					walletConnectProvider.on("disconnect", (code, reason) => {});
					// const bridge = BRIDGE_WALLETCONNECT
					// const connector = new WalletConnect({
					// 	bridge
					// })
					// connector.killSession()
					break
				case BITSKI:
					bitski.signOut()
					break;
			}
		},
		handlePopupNetwork() {
			window.onclick = (e) => {
				if (e.target.id !== 'network-name') {
					this.showNetwork = false
				}

				if (e.target.id !== 'timer-icon') {
					this.showTimerType = false
				}
			}
		},
		async getCampaignInfo(campaignId) {
			try {
				this.mxShowLoading()
				// const contractConn = getContractConnect(
				// 	this.loginBy,
				// 	ABI_STAKING,
				// 	this.staking_address,
				// 	this.networkRPC,
				// 	this.fortmaticNetwork
				// 		? this.fortmaticNetwork
				// 		: this.current_network
				// )
				// const isAllow = await contractConn.methods
				// 	.allowWithdrawAll()
				// 	.call()
				// const isAllow = true
				// const data = await contractConn.methods
				// 	.campaignInfo(campainId)
				// 	.call()

				var data = {
					'chain_id' : parseInt(this.current_network),
					'campaign_id' : campaignId
				};

				_U.callPost({
					url: gConfig.get_campaign_time,
					data: data,
					callback: (resp) => {
						if(resp.data) {						
							var data = resp.data;	
							if(data.length == 0) {
								this.isEther = false;
								this.mxCloseLoading()
								return;
							}
							this.isEther = true;

							this.poolDuration.duration = Number(data[0].endCampaign) - Number(data[0].starCampaign)
							//set time countdown
							const endValue = Number(data[0].endCampaign)
							const startValue = Number(data[0].starCampaign)
							const currValue = moment().unix()							
							console.log('time', { currValue, startValue, endValue })
							this.timeCount.startValue = startValue
							this.timeCount.endValue = endValue
							if (currValue > endValue) {
								//it's end
								this.switchStatusCampain(1)
							} else if (
								startValue <= currValue &&
								currValue <= endValue
							) {
								//had start not staking
								this.switchStatusCampain(3)
							} else if (currValue < startValue) {
								//not start yet
								this.switchStatusCampain(2)
							}
							this.startTime = moment(startValue * 1000).format()
							this.endTime = moment(endValue * 1000).format()
							if (this.poolDuration.id === 1) {
								this.startInterVal_1(startValue, endValue)
							}
							if (this.poolDuration.id === 2) {
								this.startInterVal_2(startValue, endValue)
							}
							if (this.poolDuration.id === 3) {
								this.startInterVal_3(startValue, endValue)
							}
							this.mxCloseLoading()
						}
					},
				})
				console.log('dataCampaign', data)
			} catch (err) {
				this.mxCloseLoading()
				console.log('catch', err)
			}
		},
		switchStatusCampain(status) {
			console.log('in switch status')
			if (this.statusCampain !== status) {
				this.statusCampain = status
			}
		},
		initLoginBy() {
			if (this.loginBy === WALLETCONNECT) {
				const walletconnect = window.localStorage.getItem('walletconnect')
				let wll = JSON.parse(walletconnect)
				const chainId = formatChainId(Number(wll.chainId))
				this.setStakingAddress(chainId)
			} else if (this.loginBy === METAMASK || this.loginBy === COINBASE) {
				checkProviderWallet(this.loginBy)
				const chainId = window.localStorage.getItem('currentNetwork')
				const chainNetwork = formatChainId(Number(chainId))
				this.setStakingAddress(chainNetwork)
			} else if (this.loginBy === FORTMATIC || this.loginBy === BITSKI) {
				const chainId = window.localStorage.getItem('fortmaticNetwork')
				const chainNetwork = formatChainId(Number(chainId))
				this.setStakingAddress(chainNetwork)
			} else {
				const chainId = window.localStorage.getItem('currentNetwork')
				const chainNetwork = formatChainId(Number(chainId))
				this.setStakingAddress(chainNetwork)
			}
		},
		setStakingAddress(chainId) {
			console.log("setStakingAddress", chainId)
			this.current_network = chainId
			const networkBSC = this.$store.getters.getBscAddr.Network
			const networkPolygon = this.$store.getters.getPolygonAddr.Network
			const networkETH = this.$store.getters.getEthAddr.Network

			if (
				chainId !== networkBSC &&
				chainId !== networkETH &&
				chainId !== networkPolygon
			) {
				this.mxShowToast(MSG_METAMASK_2)
				return
			} else if (chainId === networkBSC) {
				this.staking_address = this.$store.getters.getStakingBscAddr
			} else if (chainId === networkETH) {
				this.staking_address = this.$store.getters.getStakingEthAddr
			} else if (chainId === networkPolygon) {
				this.staking_address = this.$store.getters.getStakingPolAddr
			}
			const id = this.poolDuration.id
			this.getCampaignInfo(id)
			// this.onGetNftsStaked(id)
			// this.getTotalMiningHashRate(id)
			// this.getMyMiningHashRate(id)
			// this.getTotalStaked(id)
			// this.getMyStaked(id)
		},
		campaignChange(id) {
			this.poolDuration.id = id;
			this.getCampaignInfo(id);
			this.showTimerType = false;
		},
		startInterVal_1(startValue, endValue) {
			clearInterval(this.interval_1)
			clearInterval(this.interval_2)
			clearInterval(this.interval_3)
			this.interval_1 = setInterval(() => {
				this.countInterVal(startValue, endValue)
			}, 1000)
		},
		startInterVal_2(startValue, endValue) {
			clearInterval(this.interval_1)
			clearInterval(this.interval_2)
			clearInterval(this.interval_3)
			this.interval_2 = setInterval(() => {
				this.countInterVal(startValue, endValue)
			}, 1000)
		},
		startInterVal_3(startValue, endValue) {
			clearInterval(this.interval_1)
			clearInterval(this.interval_2)
			clearInterval(this.interval_3)
			this.interval_3 = setInterval(() => {
				this.countInterVal(startValue, endValue)
			}, 1000)
		},
		countInterVal(startValue, endValue) {			
			const currentValue = moment().unix()			
			if (currentValue < startValue) {
				if (this.statusCampain !== 2) this.switchStatusCampain(2)
				this.countStart()
			}
			if (startValue <= currentValue && currentValue <= endValue) {
				if (this.statusCampain !== 3) this.switchStatusCampain(3)
				this.countEnd()
			}
			if (currentValue > endValue) {
				if (this.statusCampain !== 1) this.switchStatusCampain(1)
				clearInterval(this.interval_1)
				clearInterval(this.interval_2)
				clearInterval(this.interval_3)
				this.days = 0
				this.hours = 0
				this.mins = 0
			}
		},
		countStart() {
			const currentTime = moment(new Date()).format()
			const diffDuration = moment.duration(
				moment(this.startTime).diff(currentTime)
			)
			this.days = diffDuration.days()
			this.hours = diffDuration.hours()
			this.mins = diffDuration.minutes()
		},
		countEnd() {
			const currentTime = moment(new Date()).format()
			const diffDuration = moment.duration(
				moment(this.endTime).diff(currentTime)
			)
			this.days = Math.floor(diffDuration.asDays())
			this.hours = diffDuration.hours()
			this.mins = diffDuration.minutes()
		},
		clickMarketPlace() {
			this.isShowNavbar=false;
			_U.callSaveLog({
						keyIdx : "", 
						keyValue : 0, 
						landIdx : 0, 
						floor : 0,
						currencyType : "", 
						currencyValue : 0, 
						totalCurrency : 0,
						message : "",
						action : LogType.WEB_MARKET_LOOKUP
					})
		}
	},
	watch: {
		isShowNavbar: function () {
			this.checkStyleOverflow();
		},
		signed: function () {
			this.loginBy = window.localStorage.getItem('loginBy')
		}
	},
}
</script>

<style lang="scss" scoped>

.fade-enter-active, .fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.hidden {
	display: none;
}
.GNB {
	position: absolute;
	top:0px; left:0px;
	$tsTime: .5s ease-in-out;
	@include FLEX(center,flex-end);
	@include Transition($tsTime);
	width: 100%;
	height: gREm(164);
	
	// @include M-Field('height',gREm(120)) ;
	z-index: $Z-INDEX-GNB;
	.button-nav{
		display: none;
	}
	.gnb-box-bg {
		position:absolute;
		width:100%;
		height:100%;
		opacity: 0.8;
  		background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
	}
	.gnb-box {
		@include FLEX(space-between,flex-end);
		@include Transition($tsTime);
		width: 100%;
		height: gREm(64);		
		margin-left: gREm(260);
		margin-right: gREm(260);
		margin-bottom: gREm(19 + 44);

		.menu-box {
			@include FLEX(flex-start,center);
			.logo {
				display:block;
				cursor: pointer;
				width: gREm(126);
				height: gREm(46);
				@include SetBgImage(url('../assets/img/NV-logo.svg'));
				// @include OnOverTransition();
				margin-right: gREm(50);
			}
			.menus {
				@include FLEX(flex-start,center);
				@include Transition($tsTime);

				.info-network-mobile {
					display: none;
				}
				.menu {
					@include FLEX(flex-start,center);
					@include Transition($tsTime);
					opacity: 0.5;
					cursor: pointer;
					width:auto;
					// height: gREm(19);
					height: gREm(46);
					margin-right: gREm(50);
					@include Set-Font($AppFont, gREm(15), gREm(19), #ffffff);

					// @include OnOverTransition();

					&:hover {
						opacity: 1;
					}
				}
			}
		}
		.menu-btns {
			@include FLEX(center, center);
			@include Transition($tsTime);
			height: gREm(46);
			// margin-left: gREm(50);
			.menus {
				@include FLEX(center, center);
				@include Transition($tsTime);
				height: 100%;
				margin-left: gREm(40);
				.menu {
					@include Transition($tsTime);
					cursor: pointer;
					margin-left: gREm(40);

					.user-menu-popup-wrap {
						position: absolute;
						@include FLEXV(flex-end,center);
						top: gREm(0); left: gREm(0);
						width: gREm(190);
						height: gREm(106 + 34 + 102);
						padding-top: gREm(34 + 12);

						.user-menu-popup {
							@include FLEXV(flex-start,center);
							top: 0; left: 0; width: 100%; height: gREm(196);
							padding-top: gREm(16);
							padding-bottom: gREm(16);
							border-radius: gREm(10);
							border: solid gREm(1) #000000;
							background-color: #000000;
							.user-menu {
								@include FLEX(flex-start,center);
								width: 100%;
								padding-left: gREm(25);
								padding-right: gREm(40);
								height: gREm(37);
								@include Set-Font($AppFont, gREm(15), gREm(30), #ffffff);
								background-color: #000000;
								cursor: pointer;
								&:hover {
									color: #6c38ef;
									background-color: #000000;

								}
							}
						}

						&.lang {
							left: gREm(-190 + 78);
							height: gREm(69 + 34 + 12);
							.user-menu-popup {
								height: gREm(69);
							}
						}

					}
				}
				.menu.btn {
					height: gREm(40);
					width: gREm(134);
					margin-left: gREm(40);
					border-radius: gREm(6);
					@include Set-Font($AppFont, gREm(14), gREm(18), #ffffff);
					text-align: center;
					@include OnOverTransition();
				}
				.text {
					@include Set-Font($AppFont, gREm(15), gREm(40), #ffffff);
					opacity: 0.5;
					// @include OnOverTransition();
					&:hover {
						opacity: 1;
					}
				}
			}

			.info-network {
				margin-left: gREm(16);
				cursor: pointer;

				.network-icon {
					font-family: $AppFont;
				}
				.network-popup {
					display: flex;
					gap: gREm(30);
					border-radius: gREm(8);
					margin-top: gREm(16);
					position: absolute;
					background-color: #ffffff;
					padding: gREm(10) gREm(20);
					left: gREm(-37.5);
				}

				.network-wrapper {
					display: flex;
					flex-direction: column;
					align-items: center;

					.icon {
						width: gREm(30);
						height: gREm(30);
						margin-bottom: gREm(8);
					}

					label {
						color: #000000;
						font-size: gREm(14);
						line-height: gREm(22);
						font-family: $AppFont;
					}
				}
			}
		}
		.menu:after {
			@include Transition($tsTime);
			@include H-Center;
			opacity: 0;
			position: absolute;
			display: block;
			content:"";
			text-align: center;
			bottom: gREm(13 - 16 - 5); // 19 -> 46 ==> 27 / 2 => 13
			width: gREm(5);
			height: gREm(5);
			border-radius: 50%;
			background-color: #f6583e;
		}
		.menus .menu[active="on"] {
			opacity: 1;
		}
		.menus .menu[active="on"]:after {
			opacity: 1;
		}
	}	
	.menus-mobile {
		position:absolute;
		@include FLEX(center,center);
		z-index: -1;
		opacity: 0;
		top: gREm(33);
		right: gREm(20);
		width: gREm(50);
		height: gREm(50);
		cursor: pointer;
		@include Transition($tsTime);
		.menu {
			@include FLEX(center,center);
			width: 100%;
			height: 100%;
			@include SetBgImage(url('../assets/img/btn-menu.svg'));

		}
	}
}
.gnb-timer {
	position: absolute;
	top:gREm(19 + 44 + 64); left: 0;
	width: 50%;
	z-index: $Z-INDEX-GNB;
	$tsTime: .5s ease-in-out;	
	@include Transition($tsTime);
	@include FLEX(start,flex-end);
	margin-left: gREm(260 + 126 + 50);
	margin-right: gREm(260);
	.gnb-timer-div {
		display: flex;
	}
	.timer {
		cursor: pointer;
		width: gREm(370);
		height: gREm(46.7);
	}
	.timer-span {
		@include FLEX(center, center);
		height: gREm(46.7);
		margin-left: gREm(15);
		@include Set-Font($AppFont, gREm(15), gREm(19), #ffffff);		
		.on-going {
			@include FLEX(center,center);
			@include Set-Font($AppFont, gREm(15), gREm(19), #ffffff);
		}
		.reward {
			@include FLEX(center,center);
			@include Set-Font($AppFont, gREm(15), gREm(19), #F0CFBD);
		}		
	}
	.span-mobile {
		display: block;
		height: gREm(20);
		.on-going {
			height: gREm(30);
		}
		.reward {
			height: gREm(30);
		}
	}
	.user-menu-popup-wrap {
		position: absolute;
		@include FLEXV(flex-end,center);
		top: gREm(60); left: gREm(0);
		width: gREm(370);
		height: gREm(106 + 34);
		z-index: $Z-INDEX-GNB;

		.user-menu-popup {
			@include FLEXV(flex-start,center);
			top: 0; left: 0; width: 100%; height: gREm(196);
			padding-top: gREm(16);
			padding-bottom: gREm(16);
			border-radius: gREm(10);
			border: solid gREm(1) #000000;
			background-color: #000000;
			.user-menu {
				@include FLEX(center,center);
				width: 100%;
				padding-left: gREm(25);
				padding-right: gREm(40);
				height: gREm(37);
				@include Set-Font($AppFont, gREm(15), gREm(30), #ffffff);
				background-color: #000000;
				cursor: pointer;
				&:hover {
					color: #6c38ef;
					background-color: #000000;

				}
			}
		}
	}
}

.GNB[signed="on"] {
	.gnb-box {
		.wallet-balance {
					display: flex;
					align-items: center;
					font-family: $AppFont;
					font-size: gREm(15);
					line-height: gREm(19);
					margin-left: gREm(8);

					.icon {
						width: gREm(22);
						height: gREm(22);
						margin-right: gREm(8);
					}

					.pol-icon {
						@include SetBgImage(url('../assets/img/ic-polygon-market.svg'));
					}

					.eth-icon {
						@include SetBgImage(url('../assets/img/eth-icon.png'));
					}

					.bnb-icon {
						@include SetBgImage(url('../assets/img/bnb-icon.svg'));
					}
				}
		.menu-btns {
			.menus {
				.dvi-box {
					width: auto;
					@include FLEX(space-between, center);
					.dvi-icon {
						width: gREm(16);
						height: gREm(19);
						margin-right:gREm(8);
						@include SetBgImage(url('../assets/img/ic-dvi-market.svg'));
					}
					.dvi-value {
						width: auto;
						@include Set-Font($AppFont, gREm(15), gREm(19), #febf37);
					}
				}
				.pol-box {
					width: auto;
					@include FLEX(space-between, center);
					.pol-icon {
						width: gREm(22);
						height: gREm(19);
						margin-right:gREm(8);
						@include SetBgImage(url('../assets/img/ic-polygon-market.svg'));
					}
					.pol-value {
						width: auto;
						@include Set-Font($AppFont, gREm(15), gREm(19), #7A4ADD);
					}
				}
				
				.uinfo-icon {
					@include FLEX(center, center);
					width: gREm(35);
					height: gREm(35);
					border-radius: 50%;
					background-color: #ffffff;

					.cap-text {
						@include Set-Font($AppFont, gREm(22), gREm(24), #6c38ef, 600);
					}
				}
			}
		}
	}

}

.GNB[theme="white"] {
	.gnb-box-bg {
		background-image: none;
	}
	.gnb-box .menu-btns .menus .btn{
		background-image: linear-gradient(to right, #62ffb3 11%, #01c9c1 99%);
		color:  #003b1e;
	}
}
.GNB[theme="blue"] {
	.gnb-box-bg {
		opacity: 1;
		background-image: linear-gradient(to bottom, #6c38ef, #6c38ef);
	}
	.gnb-box .menu-btns .menus .btn{
		background-image: linear-gradient(to right, #62ffb3 11%, #01c9c1 99%);
		color: #003b1e;
	}
}

.GNB[theme="event"] {
	background-color: rgba(1, 1, 1, 0.25);
	.gnb-box-bg {
		background-image: none;
	}
}

// 1680 :: GNB 첫번째 체크 1600PX
@include media-max($media_big) {
	.GNB {
		// $r : 3/5;
		.gnb-box {
			margin-left: gRH(220 );
			margin-right: gRH(220 );
			.menu-box .menus .menu {
				margin-right: gRH(40);
			}

			.menu-btns {
				.menus {
					margin-left: gRH(30);
					.menu {
						margin-left: gRH(30);
					}
					.btn{
						margin-left: gRH(30);
					}
				}
			}
		}
	}

	.gnb-timer {
		margin-left: gRH(220 );
		margin-right: gRH(220 );
	}
};

@include media-max($media_large) { // 1200
	.GNB {
		.gnb-box {
			margin-left: gREm(40);
			margin-right: gREm(40);
			// margin-bottom: gREm(29);

			.menu-box {
				.logo {
					margin-right: gREm(40);
				}
				.menus {
					.menu {
						margin-right: gRH(30);
					}
				}
			}
			.menu-btns {
				.menus {
					margin-left: gRH(30);
					.menu {
						margin-left: gRH(30);
					}
					.btn{
						margin-left: gRH(30);
						height: gREm(40);
						width: gREm(134);
						border-radius: gREm(6);
						@include Set-Font($AppFont, gREm(14), gREm(40), #ffffff);
					}
					.text {
						@include Set-Font($AppFont, gREm(15), gREm(40), #ffffff);
					}
				}
			}
		}
	}

	.gnb-timer {
		margin-left: gRH(40 );
		margin-right: gRH(40 );
	}
}


@include media-max($media_medium) { // 1024px
	.GNB {
		.gnb-box {
			margin-left: gRH(40);
			margin-right: gRH(40);
			// margin-bottom: gREm(39);

			.menu-box {
				.logo {
					margin-right: gRH(30);
				}
				.menus {
					.menu {
						height: gREm(16);
						margin-right: gRH(26);
						@include Set-Font($AppFont, gREm(13), gREm(16), #ffffff);
					}
				}
			}
			.menu-btns {
				.menus {
					.menu {
						margin-left: gRH(26);
					}
					.menu {
						margin-left: gRH(26);
					}
					.btn{
						margin-left: gRH(30);
						height: gREm(36);
						width: gREm(124);
						border-radius: gREm(6);
						@include Set-Font($AppFont, gREm(13), gREm(36), #ffffff);
					}
					.text {
						@include Set-Font($AppFont, gREm(13), gREm(36), #ffffff);
					}
				}
			}
		}
	}

	.gnb-timer {
		margin-left: gRH(40 );
		margin-right: gRH(40 );
	}
}


@include media-max($media_small) { // 768px
	.GNB {
		transition: none;
		&.shadow{
					background: #181721;
					z-index: 999;
				box-shadow: -0 5px 5px 0px rgba(64,57,57,0.96);
				-webkit-box-shadow: -1px 5px 5px 0px rgba(64,57,57,0.96);
				-moz-box-shadow: -1px 5px 5px 0px rgba(64,57,57,0.96);
	
		}
		height: gREm(124);
		position: relative;
		.button-nav{
			display: block;
			position: absolute;
			top: 50%;
			right: gREm(20);
			transform: translate(-50%, -50%);
			z-index: 99;
		}
		.gnb-box {
			margin-bottom: gREm(39);
			margin-left: gREm(20);
			margin-right: gREm(20);
			.menu-box {
				.logo {
					width: gREm(126);
					height: gREm(46);

					&:focus {
						outline: none !important;
					}
				}
				.menus{
					display: none;
				}
			}
			&.active {
				.menu-box .menus {
					display: block;
					opacity: 1;
					z-index: 9;
					position: fixed;
					top: 124px;
					left: 0;
					bottom: 0;
					right: 0;
					width: 100vw;
					height: auto;
					background: #181721;
					padding: 40px 20px;
					overflow-y: auto;
					overflow-x: hidden;

					.info-network-mobile {
						display: block;
						font-size: gREm(13);
						font-family: $AppFont;
						margin-bottom: gREm(32);

						.network-wrapper {
							margin: gREm(8) 0;
							display: flex;
							align-items: center;
							gap: gREm(8);
							.icon {
								width: gREm(20);
								height: gREm(20);
							}
						}
					}
					& .menu {
						text-transform: uppercase;
						margin-bottom: gREm(32);
						width: 100%;
						position: relative;
						&::after{
							right: 0;
							top: 50%;
							left: unset;
							transform: translate(-50%, -50%);
						}
					}
					& .menu-btns-mobile{
						& .dvs-nav-item{
							position: relative;
							width: 100%;
							height: 100%;
							min-height: gREm(88);
							display: flex;
							align-items: center;
							justify-content: space-between;
							border-top: 1px solid #2E2C3E;
							& .dvg-coin{
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 12px;
								color: #FFD041;
								font-family: Montserrat;
								font-weight: 400;
								font-size: gREm(16);
							}
							& .dvs-user{
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 12px;
								& .uinfo-icon {
									@include FLEX(center, center);
									width: gREm(35);
									height: gREm(35);
									border-radius: 50%;
									background-color: #ffffff;
									.cap-text {
										@include Set-Font($AppFont, gREm(22), gREm(24), #6c38ef, 600);
									}
								}

								.mypage {
									font-family: $AppFont;
								}
							}
							& .dvs-no-user{
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 12px;
								& .signup{
									color: #FFD041;
									opacity: 1;
								}
							}
							& .g-btn{
									width: gREm(159);
									height: gREm(48);
									border-radius: 0.375rem;
    							font-family: Montserrat, sans-serif;
    							font-size: 0.875rem;
    							font-weight: 500;
    							line-height: 1.125rem;
    							font-stretch: normal;
    							font-style: normal;
    							letter-spacing: normal;
    							white-space: nowrap;
    							color: #ffffff;
    							text-decoration: none;
    							text-align: center;
    							transition: transform 0.5s ease-in-out;
								}

								.no-login-btn {
									background: linear-gradient(
									94.76deg,
									#62ffb3 0%,
									#01c9c1 108.93%
								);
							}
								& .menu{
									text-transform: unset;
									margin-bottom: unset;
								}
								& .user-menu-popup-wrap{
									background: #ffffff;
									border-radius: 10px;
									opacity: 1;
									position: absolute;
    							display: flex;
    							justify-content: center;
    							flex-direction: column;
    							align-items: left;
    							top: 23px;
    							width: gREm(190);
									height: auto;
									padding: 0 10px;
									& .list-lang{
										text-align: left;
										padding: 5px;
										& div{
											color: #000000;
											padding: 5px 0;
										}

									}
								}

								.logout {
									font-family: $AppFont;
								}
						}
					}
				}
			}
		.menu-btns .menus {
				display: none;
				opacity: 0;
				z-index: -1;
			}
		}
		.menus-mobile {
			display: none;
			opacity: 1;
			z-index: $Z-INDEX-MOBILE-GNB-MENU;
		}
	}

	.gnb-timer {
		@include FLEX(center, center);
		margin: 0;
		width: 100%;
		top:gREm(44 + 64);
		.gnb-timer-div {
			display: block;
		}
	}
}

#app[mobile="on"] {
	.GNB {
		.gnb-box {
			.menu-btns {
				.menus {
					.menu.btn { // START NOW
						display: none;
					}
				}
			}
		}
	}

	.gnb-timer {
		@include FLEX(center, center);
		margin: 0;
		width: 100%;
		top:gREm(44 + 64);
		.gnb-timer-div {
			display: block;
		}
	}
}

</style>
