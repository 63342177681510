<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div v-if="data.type == '1'" class="modal-container-1">                    
					<div class="image-1"></div>
                    <div class="btn-top">
                        <button class="modal-close-btn" @click="closePopup"></button>
                    </div>
					<div  class="btn-bottom">
                        <button class="btn-detail" @click="closePopup()"></button>
                    </div>
				</div>
				<div v-else class="modal-container-2">
					<div class="image-2"></div>
                    <div class="btn-top">
                        <button class="modal-close-btn" @click="closePopup"></button>
                    </div>
					<div  class="btn-bottom">
                        <button class="btn-detail" @click="closePopup()"></button>
                    </div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import AppConfig from '@/App.Config.js'
import WalletAPI from '@/features/WalletAPI.js'
var wAPI = new WalletAPI();
var gConfig = AppConfig()

export default {
	components: {},
	mounted() {		
		// this.popType = authInfo.type;
	},
	computed: {},
	data() {},
	props: {
		data: Object,
	},
	created() {
	},
	methods: {
		closePopup() {
			this.mxCloseEventNotiModal();
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-mask {
	position: absolute;
	z-index: $Z-INDEX-LOGIN-POPUP;
	// margin-top: 224px;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.5s ease;

	.modal-wrapper {
		display: table-cell;
		vertical-align: middle;

		.modal-container-1 {
			border-radius: 10px;
			max-width: 63vw;
            max-height: 45vw;
            width: gREm(790);
            height: gREm(560);
			margin: 0px auto;
			transition: all 0.3s ease;
			font-family: Montserrat, sans-serif;
			& .title {
				font-size: gREm(28);
				line-height: gREm(19);
				font-weight: 800;
				margin-bottom: gREm(30);
			}
			& .desc {
				font-family: Montserrat, sans-serif;
				font-size: gREm(16);
				font-style: normal;
				font-weight: 400;
				line-height: gREm(22);
				letter-spacing: 0em;
				text-align: left;
				margin-bottom: gREm(34);
			}	
            .image-1 {
				position: absolute;
                max-width: 63vw;
                max-height: 45vw;
				width: gREm(790);
				height: gREm(560); // gREm(475);
				bottom: 0px;
				@include SetBgImage(
					url('../assets/img/event/web/event-noti-1.png')
				);
			}
            & .btn-top {
                position: absolute;
                width: 100%;
                height: 5%;
                top: 19%;
                display: flex;
				align-items: center;
				justify-content: center;
                .modal-close-btn {
                    position: absolute;
                    height: 100%;
                    width: 3.5%;
                    right: 5%;
                    cursor: pointer;
                    @include SetBgImage(
                        url('../assets/img/event/web/close.png')
                    );
                }
            }
			& .btn-bottom {
                position: absolute;
                width: 100%;
                height: 10%;
                bottom: -5%;
                display: flex;
                align-items: center;
                justify-content: center;
                .btn-detail {
                    height: 100%;
                    width: 32.5%;
                    cursor: pointer;
					@include SetBgImage(
                        url('../assets/img/event/web/noti-ok.png')
                    );
                }
            }
		}

		.modal-container-2 {
			border-radius: 10px;
			max-width: 63vw;
            max-height: 61.5vw;
            width: gREm(790);
            height: gREm(782);
			margin: 0px auto;
			transition: all 0.3s ease;
			font-family: Montserrat, sans-serif;
			& .title {
				font-size: gREm(28);
				line-height: gREm(19);
				font-weight: 800;
				margin-bottom: gREm(30);
			}
			& .desc {
				font-family: Montserrat, sans-serif;
				font-size: gREm(16);
				font-style: normal;
				font-weight: 400;
				line-height: gREm(22);
				letter-spacing: 0em;
				text-align: left;
				margin-bottom: gREm(34);
			}
			.image-2 {
				position: absolute;
                max-width: 63vw;
                max-height: 61.5vw;
				width: gREm(790);
				height: gREm(782); // gREm(475);
				bottom: 0px;
				@include SetBgImage(
					url('../assets/img/event/web/event-noti-2.png')
				);
			}
            & .btn-top {
                position: absolute;
                width: 100%;
                height: 5%;
                top: 13%;
                display: flex;
				align-items: center;
				justify-content: center;
                .modal-close-btn {
                    position: absolute;
                    height: 100%;
                    width: 3.5%;
                    right: 5%;
                    cursor: pointer;
                    @include SetBgImage(
                        url('../assets/img/event/web/close.png')
                    );
                }
            }
			& .btn-bottom {
                position: absolute;
                width: 100%;
                height: 7%;
                bottom: -3.5%;
                display: flex;
                align-items: center;
                justify-content: center;
                .btn-detail {
                    height: 100%;
                    width: 31.5%;
                    cursor: pointer;
					@include SetBgImage(
                        url('../assets/img/event/web/noti-ok.png')
                    );
                }
            }
		}
	}
}

@include media-max($media_small) {
	.modal-mask {
		.modal-wrapper {
			.modal-container-1 {
				max-width: 85vw;
				width: 100%;
				// margin: 0;

                .image-1 {
                    max-width: 85vw;
                    max-height: 60vw;
                }

				.title {
					@include Set-Font(
						$AppFont,
						gREm(22),
						gREm(32),
						#ffffff,
						600
					);
					white-space: pre-wrap;
				}

				.desc {
					line-height: gREm(24);
				}

				.btn-success {
					font-size: gREm(14);
				}

                & .btn-top {
                    height: 7%;
                    top: -5%;
                    .modal-close-btn {
                        right: 6%;
                    }
                }
				
				& .btn-bottom {
					.btn-detail {
						width: 25%;
					}
				}
			}

			.modal-container-2 {
				max-width: 85vw;
				width: 100%;
				// margin: 0;

                .image-2 {
                    max-width: 85vw;
                    max-height: 84.5vw;
                }

				.title {
					@include Set-Font(
						$AppFont,
						gREm(22),
						gREm(32),
						#ffffff,
						600
					);
					white-space: pre-wrap;
				}

				.desc {
					line-height: gREm(24);
				}

				.btn-success {
					font-size: gREm(14);
				}

                & .btn-top {
                    height: 7%;
                    top: -20%;
                    .modal-close-btn {
                        right: 6%;
                    }
                }
				
				& .btn-bottom {
					.btn-detail {
						width: 25%;
					}
				}
			}
		}
	}
}
</style>
