<template>
	<transition name="modal">
		<div class="alert-box">
			<div class="message-box">
				<div class="close-btn" @click="mxCloseImageModal()"></div>
				<div class="message" v-html="$store.state.showImage.msg"></div>
				<img 
          v-if="$store.state.showImage.image" 
          :src="$store.state.showImage.image" 
          width="256"
          height="256"
        />
				<vue-recaptcha
					v-show="showRecaptcha"
					siteKey="6Lf_qD4eAAAAAAfk_LMuquaBtTSOt2Fqh-h8_vhz"
					size="normal"
					theme="light"
					lang="en"
					@verify="recaptchaVerified"
					@expire="recaptchaExpired"
					@fail="recaptchaFailed"
					ref="vueRecaptcha"
				>
				</vue-recaptcha>
				<div
					v-if="isShowBtn && $store.state.showImage.btn"
					class="btn g-btn"
					@click="mxCloseImageModal()"
				>
					{{
						$store.state.showImage.btn
							? $store.state.showImage.btn
							: $t('btn.ok')
					}}
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2'

export default {
	components: {
    vueRecaptcha,
  },
	mounted() {		
		// this.popType = authInfo.type;
	},
	computed: {},
	data() {
    return {
      isShowBtn: false,
      showRecaptcha: true
    }
  },
	props: {
		data: Object,
	},
	created() {
	},
	methods: {
		closePopup() {
			this.mxCloseMainNotiModal();
		},
    recaptchaVerified(response) {
			setTimeout(() => {
				this.showRecaptcha = false
				this.isShowBtn = true
			}, 500)
		},
    recaptchaExpired() {
			this.$refs.vueRecaptcha.reset()
		},
		recaptchaFailed() {
			console.log('failed')
		},
	},
}
</script>

<style lang="scss" scoped>

</style>
