<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div>
						<form class="frm_auth frm_signin form">
							<BaseButton
								type="button"
								class="closebtn"
								@click="closePopup"
							>
							</BaseButton>

							<div class="welcome" @click="visibleFacebookBtnCountMethods">
								{{ $t('login.popup.welcome-msg') }}
							</div>
							<div class="popup-title">
								{{ $t('login.popup.title') }}
							</div>

							<div class="modal-logins-container">
								<div class="modal-logins">
								<BaseButton
								type="button"
								class="connectbtn metamask-btn"
								@click="connectMetamask"
							>
								<img
									src="../assets/img/ic-metamask.svg"
									alt=""
									class="ic-image"
								/>
								Metamask
								</BaseButton>

								<BaseButton
									type="button"
									class="connectbtn walletconnect-btn"
									@click="connectWalletConnect"
								>
									<img
										src="../assets/img/ic-walletconnect.svg"
										alt=""
										class="ic-image"
									/>
									WalletConnect
								</BaseButton>
								<BaseButton
									type="button"
									class="connectbtn coinbase-btn"
									@click="connectCoinbase"
								>
									<img
										src="../assets/img/ic-coinbase.svg"
										alt=""
										class="ic-image"
									/>
									Coinbase Wallet
								</BaseButton>
								<BaseButton
									type="button"
									class="connectbtn fortmatic-btn"
									@click="connectFortmatic"
								>
									<img
										src="../assets/img/ic-fortmatic.svg"
										alt=""
										class="ic-image"
									/>
									Fortmatic
								</BaseButton>
								<BaseButton
									type="button"
									class="connectbtn bitski-btn"
									@click="connectBitski"
								>
									<img
										src="../assets/img/ic-bitski.png"
										alt=""
										class="ic-image"
									/>
									Bitski
								</BaseButton>
								<BaseButton
									type="button"
									class="connectbtn clover-btn"
									@click="connectClover"
								>
									<img
										src="../assets/img/ic-clover.png"
										alt=""
										class="ic-image"
									/>
									CLV
								</BaseButton>
								<div class="or" v-if="isVisibleFacebookBtn">
									<span class="line"></span>
									<span class="text">or</span>
									<span class="line"></span>
								</div>
								<BaseButton
									type="button"
									class="connectbtn metamask-btn"
									@click="loginWithGoogle"
									v-if="isVisibleFacebookBtn"
								>
									<img
										src="../assets/img/ic-google.png"
										alt=""
										class="ic-image"
									/>
									Google
								</BaseButton>
								<BaseButton
									type="button"
									class="connectbtn coinbase-btn"
									@click="loginWithFacebook"
									v-if="isVisibleFacebookBtn"
								>
									<img
										src="../assets/img/ic-facebook.png"
										alt=""
										class="ic-image"
									/>
									Facebook
								</BaseButton>
								
								<div class="or">
									<span class="line"></span>
									<span class="text">or</span>
									<span class="line"></span>
								</div> 
								<div class="login-input">
									<input
										v-model="idLogin"
										type="text"
										placeholder="Please enter ID..."
									/>
								</div>
								<div class="login-pwd">
									<input
										v-model="passwordLogin"
										:type="passwordType"
										placeholder="***********"
									/>
									<img
										v-if="passwordType === 'password'"
										class="img-eye"
										src="../assets/img/ic-eye.svg"
										alt="eye"
										@click="handleEyeClick()"
									/>
									<img
										class="img-eye"
										v-else
										src="../assets/img/eye-close.svg"
										@click="handleEyeClick()"
										alt="eye-close"
									/>
								</div>
								<!-- TODO: Make selection UI for ID/PW login -->
								<!-- <div class="id">
									<div class="title">{{$t('login.popup.label-id')}}</div>
									<div class="input-box">
										<input class="field-input"
											id="login-input-field"
											@focusin="idFocus = true;"
											@blur="onBlur('id');"
											@input="onInputId"
											aria-invalid="false"
											v-model="fields_signin.id"
											maxlength= "30"
											minlength= "5"
										>
										<div class="icon" @click="clearInput" :active="(idFocus && idLength ? 'on' :'off' )"></div>
									</div>
									<div class="line" :active="(idFocus ? 'on' :'off')"></div>
								</div>

								<div class="password">
									<div class="title">{{$t('login.popup.label-pwd')}}</div>
									<div class="input-box">
										<input class="field-input"
											type='password'
											id="2"
											@focusin="passwordFocus = true;"
											@blur="onBlur('password');"
											@input="onInputPassword"
											aria-invalid="false"
											v-model="fields_signin.password"
											maxlength= "26"
											minlength= "6"
										>
										<div class="icon" @click="clearPWDInput" :active="( passwordFocus && passwordLength ? 'on' :'off')"></div>
									</div>
									<div class="line" :active="(passwordFocus ? 'on' :'off')"></div>
								</div> -->

								<div class="remember-me">
									<!-- <div class="check-box" @click="onClickRememberMe()" :active="(checkedRememberMe ? 'on' :'off')">
										<div class="checks"></div>
										<div class="remember">{{$t('login.popup.remember-me')}}</div>
									</div> -->
									<div class="forgot-msg">
										<span
											class="text forgot-id remove-highlight"
											@click="goFindId"
											>{{
												$t('login.popup.forgot') +
												' ' +
												$t('login.popup.label-id')
											}}</span
										>
										<span class="text forgot-slash">{{
											' / '
										}}</span>
										<span
											class="text forgot-pwd remove-highlight"
											@click="goFindPwd"
											>{{
												$t('login.popup.label-pwd') + '?'
											}}</span
										>
									</div>
								</div>

								<BaseButton
									@click="loginWithEmail"
									type="button"
									class="login-btn"
									>Login</BaseButton
								>

								<!-- <BaseButton type="button" class="loginbtn g-btn" @click="signin">
									{{$t('login.popup.btn-login')}}
								</BaseButton> -->

								<div class="signup-box">
									<div class="signup-box-wrap">
										<div class="notamember">
											{{ $t('login.popup.not-a-member') }}
										</div>
										<div
											class="signup-msg"
											@click="goSignUpAgreement"
										>
											{{ $t('login.popup.sign-up') }}
										</div>
									</div>
								</div>
							</div>
							</div>
							

							
						</form>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import AppConfig from '@/App.Config.js'
var gConfig = AppConfig()

import sha256 from 'crypto-js/sha256'
import WalletAPI from '@/features/WalletAPI.js'
var wAPI = new WalletAPI()

import {
	ADDRESS_METAMASK,
	BRIDGE_WALLETCONNECT,
	DEFAULT_ETH_JSONRPC_URL,
	BSC_CHAIN_ID,
	FORTMATIC_API_KEY,
	BSC_RPC_ENDPOINT,
	VALUE_LOGIN,
	MATIC_RPC_ENDPOINT,
	MATIC_CHAIN_ID,
	METAMASK,
	COINBASE,
	CLOVER,
	GOOGLE,
	FACEBOOK,
	ETH_CHAIN_ID,
	ETH_RPC_ENDPOINT,
	checkProviderWallet,
	FORTMATIC,
	WALLETCONNECT,
	BITSKI,
	formatChainId
} from '@/features/Common.js'
import {
	coinbaseProvider,
	fortmaticProvider,
	bitski,
	walletConnectProvider,
	shouldForceModalOpen
} from '@/features/Connectors.js'
import Web3 from 'web3'
import { MSG_METAMASK_1 } from '@/features/Messages.js'
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from "firebase/auth"

export default {
	mounted() {
		// this.popType = authInfo.type;
		if (this.$cookies.isKey('remembered-id')) {
			var rid = this.$cookies.get('remembered-id')
			if (rid) {
				this.fields_signin.id = rid
				this.checkedRememberMe = true
			}
		}
	},
	data() {
		return {
			idFocus: false,
			idLength: false,
			passwordFocus: false,
			passwordLength: false,
			checkedRememberMe: false,
			categories: [
				'sustainability',
				'nature',
				'animal welfare',
				'housing',
				'education',
				'food',
				'community',
			],
			fields_signin: {
				id: '',
				password: '',
			},
			passwordType: 'password',
			idLogin: '',
			passwordLogin: '',
			isClose: false,
			isVisibleFacebookBtn: true,
			visibleFacebookBtnCount: 0
		}
	},
	props: {
		setProviderWalletCon: Function,
	},
	methods: {
		visibleFacebookBtnCountMethods(){
			console.log(this.visibleFacebookBtnCount);
			if(++this.visibleFacebookBtnCount > 5){
				this.visibleFacebookBtnCount = 0
				this.isVisibleFacebookBtn = !this.isVisibleFacebookBtn;
			}
		},
		loginWithEmail() {
			const data = {
				account: this.idLogin,
				password: sha256(this.passwordLogin).toString(),
				key: '0',
				value: VALUE_LOGIN,
				usedomain: gConfig.use_domain,
			}
			this.reqLogin(data, true)
		},
		handleEyeClick() {
			this.passwordType =
				this.passwordType === 'password' ? 'text' : 'password'
		},
		closePopup() {
			this.$store.dispatch('showLoginPopup', false)
			// this.$emit('close-auth')
		},

		onBlur(v) {
			if (v == 'id') {
				setTimeout(() => {
					this.idFocus = false
				}, 500)
			} else if (v == 'password') {
				setTimeout(() => {
					this.passwordFocus = false
				}, 500)
			}
		},

		onClickRememberMe() {
			this.checkedRememberMe = this.checkedRememberMe ? false : true
			console.log(
				'==================== ',
				this.checkedRememberMe,
				this.fields_signin.id
			)
			if (this.checkedRememberMe) {
				this.$cookies.set(
					'remembered-id',
					this.fields_signin.id,
					'365d'
				)
			} else {
				if (this.$cookies.isKey('remembered-id')) {
					this.$cookies.remove('remembered-id')
				}
			}
		},

		clearInput() {
			this.fields_signin.id = ''
			this.idLength = false
		},
		clearPWDInput() {
			this.fields_signin.password = ''
		},
		onInputId() {
			this.idLength =
				this.fields_signin.id && this.fields_signin.id.length > 0
					? true
					: false
			if (this.checkedRememberMe == true && this.fields_signin.id) {
				this.$cookies.set(
					'remembered-id',
					this.fields_signin.id,
					'365d'
				)
			} else {
				if (this.$cookies.isKey('remembered-id')) {
					this.$cookies.remove('remembered-id')
				}
			}
		},

		onInputPassword() {
			this.passwordLength =
				this.fields_signin.password &&
				this.fields_signin.password.length > 0
					? true
					: false
		},

		goSignUpAgreement() {
			this.closePopup()
			// TODO: change terms agree page after terms of policy is ready
			this.$router.push({
				name: 'Signup-Page',
				params: { page: 'register' },
			})
			// this.$router.push({name:"Signup-Page", params:{page: 'agree'}});
			// this.$emit('change-auth', { type: 'signup-agreement' })
		},

		goFindId() {
			this.closePopup()
			this.$router.push({
				name: 'Signup-Page',
				params: { page: 'idphone' },
			})
		},
		goFindPwd() {
			this.closePopup()
			this.$router.push({
				name: 'Signup-Page',
				params: { page: 'pwdphone' },
			})
		},
		async connectMetamask(data = null, loginWithEmail = false) {
			const provider = checkProviderWallet(METAMASK)
			console.log('[Login] connect metamask account')
			const rv = await wAPI.checkMetamask(provider)
			// if(rv === 'NO-METAMASK' || !provider) {
			if (rv === 'NO-METAMASK') {
				window.open('https://metamask.io/download/', '_blank')
			} else if (rv !== 'NONE') {
				wAPI.Request_Account((resp) => {
					if (resp.res_code == 200) {
						const account = _U.getIfDefined(resp, [
							'data',
							'account',
						])
						if (data && loginWithEmail) {
							if (account === data.wlt.currentAccount) {
								return this.handleLogicLoginWithId(
									data,
									METAMASK
								)
							}
							this.mxShowToast(MSG_METAMASK_1)
							return
						}
						if (account) {
							wAPI.Sign_Account(
								account,
								this.reqLogin,
								provider,
								1
							)
							this.mxSetNetwork(rv)
							window.localStorage.setItem('loginBy', METAMASK)
							// window.localStorage.setItem('currentNetwork',chainId)
							return
						}
					}
					this.mxShowAlert({
						msg:
							this.$t('signup.register.error-on-wallet-url') +
							'\n' +
							this.$t('popup.metamask-request-error') +
							'\n' +
							resp.res_code,
					})
				})
			} else {
				this.mxShowAlert({
					msg:
						this.$t('signup.register.error-on-wallet-url') +
						'\n' +
						this.$t('popup.metamask-chain-not-matched'),
				})
			}
		},
		async connectCoinbase(data = null, loginWithEmail = false) {
			const provider = checkProviderWallet(COINBASE)
			console.log('[Login] connect coinbase account')
			coinbaseProvider.enable().then(async (accounts) => {
				const rv = await wAPI.checkMetamask()
				if (rv !== 'NONE') {
					if (data && loginWithEmail) {
						if (accounts[0] === data.wlt.currentAccount) {
							return this.handleLogicLoginWithId(data, COINBASE)
						}
						this.mxShowToast(MSG_METAMASK_1)
						return
					}
					if (accounts) {
						wAPI.Sign_Account(
							accounts[0],
							this.reqLogin,
							provider,
							2
						)
						this.mxSetNetwork(rv)
						window.localStorage.setItem('loginBy', COINBASE)
					} else if (error) {
						this.mxShowAlert({ msg: 'error' })
					}
				} else {
					this.mxShowAlert({
						msg:
							this.$t('signup.register.error-on-wallet-url') +
							'\n' +
							this.$t('popup.coinbase-chain-not-matched'),
					})
				}
			})
		},
		async connectBitski(data = null, loginWithEmail = false) {
			const res = await bitski.signIn()
			if (res) {
				const provider = bitski.getProvider()
				this.setlocalStorage()
				if (data && loginWithEmail) {
					if (res.accounts[0] === data.wlt.currentAccount) {
						return this.handleLogicLoginWithId(data, BITSKI)
					}
					this.mxShowToast(MSG_METAMASK_1)
					return
				}
				this.reqLogin({ wallet_addr: res.accounts[0], wallet: 5 })
				window.localStorage.setItem('loginBy', BITSKI)
			}
		},
		async connectFortmatic(data, loginWithEmail = false) {
			try {
				let web3 = new Web3(fortmaticProvider.getProvider())
				var ref = this
				web3.eth.getAccounts((error, accounts) => {
					if (error) throw error
					const from = accounts[0]
					const msgToShow = 'Welcome to Dvision World.'
					const msg = `0x${Buffer.from(msgToShow, 'utf8').toString(
						'hex'
					)}`
					const params = [msg, from]
					const method = 'personal_sign'
					web3.currentProvider.sendAsync(
						{
							id: 1,
							method,
							params,
							from,
						},
						(error, result) => {
							if (error) throw error
							this.setlocalStorage()
							if (!loginWithEmail) {
								ref.reqLogin({ wallet_addr: from, wallet: 4 })
								window.localStorage.setItem(
									'loginBy',
									FORTMATIC
								)
							} else {
								if (accounts[0] === data.wlt.currentAccount) {
									return this.handleLogicLoginWithId(
										data,
										FORTMATIC
									)
								}
								this.mxShowToast(MSG_METAMASK_1)
								return
							}
						}
					)
				})
			} catch (err) {
				console.log('catch', err)
			}
		},
		async connectWalletConnect(data, loginWithEmail = false) {
			try {
				if(shouldForceModalOpen == true) {
					await walletConnectProvider.qrcodeModal.open(walletConnectProvider.connector.uri, () => {}, { bridge: walletConnectProvider.bridge })
				}
				else {
					await walletConnectProvider.enable()
				}
			}
			catch(error) {
				await walletConnectProvider.close();
				return;
			}
			const web3 = new Web3(walletConnectProvider)
			const accounts = await web3.eth.getAccounts()
			const dataLocal = JSON.parse(
				window.localStorage.getItem('walletconnect')
			)
			if (dataLocal) {
				window.localStorage.setItem('currentNetwork', formatChainId(dataLocal.chainId))
			}
			if (data && loginWithEmail) {
				if (accounts[0] === data.wlt.currentAccount) {
					return this.handleLogicLoginWithId(data, WALLETCONNECT)
				}
				this.mxShowToast(MSG_METAMASK_1)
				return
			} else if (accounts) {
				const data = {
					wallet_addr: accounts[0],
					wallet: 3,
				}
				this.reqLogin(data)
				window.localStorage.setItem('loginBy', WALLETCONNECT)
			} else {
				this.mxShowAlert({ msg: 'error' })
			}
			// const connector = walletConnectConnector
			// if (!connector.connected) {
			// 	// create new session
			// 	await connector.createSession()
			// } else {
			// 	const data = {
			// 		wallet_addr: connector._accounts[0],
			// 		wallet: 3,
			// 	}
			// 	this.reqLogin(data)
			// 	// connector.killSession()
			// 	return
			// }

			// connector.on('connect', (error, payload) => {
			// 	console.log(payload, error)
			// 	const { accounts } = JSON.parse(
			// 		JSON.stringify(payload.params[0])
			// 	)
			// 	if (data && loginWithEmail) {
			// 		if (accounts[0] === data.wlt.currentAccount) {
			// 			return this.handleLogicLoginWithId(data, WALLETCONNECT)
			// 		}
			// 		this.mxShowToast(MSG_METAMASK_1);
			// 		return;
			// 	}
			// 	if (accounts) {
			// 		const data = {
			// 			wallet_addr: accounts[0],
			// 			wallet: 3,
			// 		}
			// 		this.reqLogin(data);
			// 		window.localStorage.setItem('loginBy',WALLETCONNECT)
			// 	} else if (error) {
			// 		this.mxShowAlert({ msg: 'error' })
			// 	}
			// })
		},
		async connectClover() {
			if(!window.clover) {
				window.open('https://clv.org/?type=wallet', '_blank')
			}
			else {
				const accounts = await window.clover.request({ method: 'eth_requestAccounts' })
				const provider = checkProviderWallet(CLOVER)
				const rv = await wAPI.checkMetamask(provider)
				
				const data = {
					wallet_addr: accounts[0],
					wallet: 6,
				}
				this.reqLogin(data)
				window.localStorage.setItem('loginBy', CLOVER)
			}
		},
		async loginWithGoogle() {
			const auth = getAuth();
			const provider = new GoogleAuthProvider();
			signInWithPopup(auth, provider)
			.then((result) => {
				
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const accessToken = credential.accessToken;
				const user = result.user;  

				//console.log("[loginWithGoogle] accessToken : ", accessToken);
				//console.log("[loginWithGoogle] user : ", user);

				user.getIdToken().then(function(idToken) {
					this.reqSnsLogin(idToken, 3);
				}.bind(this));
				
			}).catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				const email = error.customData.email;
				const credential = GoogleAuthProvider.credentialFromError(error);
				console.log(errorCode, errorMessage, email, credential);
			});
			this.closePopup();
		},
		async loginWithFacebook() {
			const auth = getAuth();
			const provider = new FacebookAuthProvider();
			provider.addScope('user_birthday');
			provider.setCustomParameters({
				'display': 'popup'
			});
			signInWithPopup(auth, provider)
			.then((result) => {
				
				const user = result.user;
				const credential = FacebookAuthProvider.credentialFromResult(result);
				const accessToken = credential.accessToken;

				//console.log("[loginWithFacebook] accessToken : ", accessToken);
				//console.log('[loginWithFacebook] user : ', user);

				user.getIdToken().then(function(idToken) {
					this.reqSnsLogin(idToken, 5);
				}.bind(this));
				
			}).catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				const email = error.customData.email;
				const credential = FacebookAuthProvider.credentialFromError(error);
				console.log(errorCode, errorMessage, email, credential);
			});
			this.closePopup();
		},
		reqSnsLogin(idToken, platformType){
			var tmp = {
				platform_id: null,
				passToken: idToken,
				platform_type: platformType
			};
			//console.log('[tmp]', tmp);
			_U.callPost({
				url: gConfig.sns_login_url,
				dataType: 'application/json',
				data: tmp,
				callback: (resp) => {
					console.log("[loginWithGoogle] result ", resp.data);
					let webAuthData = resp.data.webAuthData;
					const data = {
						wallet_addr: webAuthData.wallet_addr,
						wallet: webAuthData.wallet
					}
					this.reqLoginCallback(webAuthData);
					switch(platformType){
						case 3: window.localStorage.setItem('loginBy', GOOGLE); break;
						case 5: window.localStorage.setItem('loginBy', FACEBOOK); break;
						default:
					}
				},
			})
		},
		signin() {
			console.log("[Login] ID/PW login");

			var data = {
				account: this.fields_signin.id,
				password: sha256(this.fields_signin.password).toString()
			}

			this.reqLogin(data);
		},
		setlocalStorage() {
			const currentNetwork = window.localStorage.getItem('currentNetwork')
			if (currentNetwork && currentNetwork.length > 0) {
				window.localStorage.setItem('fortmaticNetwork', currentNetwork)
				switch (currentNetwork) {
					case '0x5':
					case '5':
						window.localStorage.setItem(
							'networkRPC',
							ETH_RPC_ENDPOINT
						)
						break
					case '0x61':
					case '97':
						window.localStorage.setItem(
							'networkRPC',
							BSC_RPC_ENDPOINT
						)
						break
					case '0x13881':
					case '80001':
						window.localStorage.setItem(
							'networkRPC',
							MATIC_RPC_ENDPOINT
						)
						break
					default:
						window.localStorage.setItem(
							'networkRPC',
							ETH_RPC_ENDPOINT
						)
						window.localStorage.setItem(
							'fortmaticNetwork',
							ETH_CHAIN_ID
						)
				}
			} else {
				window.localStorage.setItem('networkRPC', ETH_RPC_ENDPOINT)
				window.localStorage.setItem('fortmaticNetwork', ETH_CHAIN_ID)
			}
		},
		reqLogin(data, loginWithEmail = false) {
			console.log('[Auth] login() ', data)
			
			_U.callPost({
				url: gConfig.login_url,
				dataType: 'application/json',
				data: data,
				callback: (resp) => {
					let rdata = resp.data
					if (rdata && typeof rdata == 'string') {
						this.mxShowToast(rdata)
					} else if (
						rdata &&
						typeof rdata == 'object' &&
						rdata.length > 0
					) {
						if (rdata[0]) {
							const userInfo = { ...rdata[0] }
							window.localStorage.setItem(ADDRESS_METAMASK, data.wallet_addr)
							this.reqLoginCallback(userInfo, loginWithEmail);
						}
					}
				},
			})
		},
		reqLoginCallback(userInfo, loginWithEmail){
			userInfo.oqs_no = ''
			// userInfo.wallet_addr = data.wallet_addr ? data.wallet_addr
			const wlt = {
				currentAccountIdx: 0,
				currentAccount: userInfo.wallet_addr,
				accounts: [userInfo.wallet_addr],
				balance: 0,
				updated: true,
			}
			if (loginWithEmail) {
				const data = {
					wlt,
					userInfo,
				}
				switch (userInfo.wallet) {
					case '9999':
						this.handleLogicLoginWithId(data, METAMASK);
						break;
					case '1':
						this.connectMetamask(data, true)
						break
					case '2':
						this.connectCoinbase(data, true)
						break
					case '3':
						this.connectWalletConnect(data, true)
						break
					case '4':
						this.connectFortmatic(data, true)
						break
					case '5':
						this.connectBitski(data, true)
						break
					case '6':
						this.connectClover(data, true)
						break
					default:
						this.mxShowToast('Invalid wallet')
						break
				}
				return
			}
			this.mxSetWallet(wlt)
			this.$store.dispatch('setUserInfo', userInfo)
			this.$cookies.set(
				'userInfo',
				userInfo,
				gConfig.getUserInfoCookieExpireTime()
			)
			
			this.get4thLandList(userInfo.wallet_addr);
			this.closePopup()			
			if (
				this.$route.name == 'Signup-Page' ||
				this.$route.name == 'Catalyst'
			) {
				if (
					this.$route.query.nextName ==
					'CreateProposal'
				) {
					this.$router.push({
						name: this.$route.query.nextName,
						params: {
							category:
								this.$route.query.nextParams,
						},
					})
				} else {
					this.$router.go(-1)
				}
			} else if (this.$route.name === 'Dao-Detail') {
				window.location.reload()
			}
		},
		handleLogicLoginWithId(data, site) {
			this.mxSetWallet(data.wlt)
			this.$store.dispatch('setUserInfo', data.userInfo)
			this.$cookies.set(
				'userInfo',
				data.userInfo,
				gConfig.getUserInfoCookieExpireTime()
			)
			window.localStorage.setItem('loginBy', site)
			this.closePopup()
			if (this.$route.name == 'Signup-Page') {
				this.$router.push({ name: 'Home' })
			}
		},
		get4thLandList(address) {			
			var data = {
				'address' : address,
				'network' : window.localStorage.getItem('currentNetwork')
			};

			_U.callPost({
				url: gConfig.get_4th_land_list,
				data: data,
				callback: (resp) => {
					if(resp.data) {
						this.mxShow4thLandSaleModal({				
							isShow: true
						});
					}
				},
			})
		}
	},
}
</script>

<style lang="scss" scoped>
.modal-mask {
	position: absolute;
	z-index: $Z-INDEX-LOGIN-POPUP;
	// margin-top: 224px;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.5s ease;

	.modal-wrapper {
		display: table-cell;
		vertical-align: middle;

		.modal-container {
			width: 480px;
			height: calc(100vh - 50px);

			margin: 0px auto;
			// padding: 20px 30px;
			background-color: #fff;
			border-radius: 20px;
			box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
			transition: all 0.3s ease;
			font-family: Helvetica, Arial, sans-serif;

			.modal-logins-container {
				overflow: auto;
				-ms-overflow-style: none; /* 인터넷 익스플로러 */
				scrollbar-width: none; /* 파이어폭스 */
				height: calc(100vh - 200px);
				margin: 0px auto;
				transition: all 0.3s ease;
				font-family: Helvetica, Arial, sans-serif;

				.modal-logins {
					& .or {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: gREm(8);
						line-height: 22px;
						margin-top: gREm(37);
						margin-bottom: gREm(31);
						& .text {
							color: #a0a0a0;
							font-size: gREm(14);
							line-height: gREm(22);
							font-family: $AppFont;
						}
						& .line {
							width: gREm(147);
							height: 1px;
							background: #e2e2e2;
						}
					}
				}
			}
			.modal-logins-container::-webkit-scrollbar {
				display: none; /* Chrome, Safari, Opera*/
			}
			
		}
	}
}

.form {
	@include FLEXV(flex-start, center);
	width: 100%;
	height: auto;
	.login-input {
		margin-bottom: gREm(14);
		& input {
			border: 1px solid rgba(24, 23, 33, 0.1);
			width: gREm(324);
			height: auto;
			padding: gREm(13) gREm(32);
			color: #181721;
			border-radius: 10px;
			font-size: gREm(14);
			line-height: gREm(22);
			font-family: $AppFont;
		}
	}
	.login-pwd {
		position: relative;
		& input {
			border: 1px solid #e2e2e2;
			width: gREm(324);
			height: auto;
			padding: gREm(13) gREm(75) gREm(13) gREm(32);
			color: #181721;
			border-radius: 10px;
			font-size: gREm(14);
			line-height: gREm(22);
			font-family: $AppFont;
		}
		& .img-eye {
			position: absolute;
			top: 50%;
			right: gREm(30);
			transform: translate(-50%, -50%);
			cursor: pointer;
		}
	}

	input::placeholder {
		color: #bababa;
	}

	.login-btn {
					display: inline-block;
		width: 100%;
		max-width: gREm(324);
		padding: gREm(17) 0;
		height: auto;
		background: linear-gradient(85.48deg, #9f52ff 0%, #3504ff 99.18%);
		border-radius: 10px;
		font-family: $AppFont;
		font-size: gREm(14);
		line-height: gREm(22);
		font-weight: 700;
		text-align: center;
		margin-top: gREm(24);
		cursor: pointer;
	}

	.closebtn {
		@include SetBgImage(url('../assets/img/close-popup.svg'));
		width: gREm(18);
		height: gREm(18);
		cursor: pointer;
		@include OnOverTransition();
		position: absolute;
		top: gREm(27);
		right: gREm(29);
	}
	.welcome {
		height: auto;
		width: 100%;
		margin-top: gREm(50);
		text-align: center;
		@include Set-Font($AppFont, gREm(14), gREm(24), #0d0c22);
	}
	.popup-title {
		height: auto;
		width: 100%;
		margin-top: gREm(8);
		margin-bottom: gREm(12);
		text-align: center;
		@include Set-Font($AppFont, gREm(28), gREm(38), #181721, 600);
		white-space: pre-wrap;
		padding: 0 gREm(20);
	}
	.id,
	.password {
		height: gREm(40+18);
		width: gREm(420);
		margin-top: gREm(72);
		.title {
			height: gREm(18);
			width: 100%;
			@include Set-Font($AppFont, gREm(14), gREm(24), #6a6a6a);
			text-align: left;
		}
		.input-box {
			width: gREm(420);
			height: gREm(20);
			margin-top: gREm(15);
			@include FLEX(space-between, center);
			.field-input {
				height: 100%;
				width: gREm(420 - 20 - 10);
				@include Set-Font($AppFont, gREm(16), gREm(24), #0d0c22);
				text-align: left;
			}
			.icon {
				&[active='on'] {
					width: gREm(20);
					height: gREm(20);
					cursor: pointer;
					@include SetBgImage(
						url('../assets/img/ic-closed-input-n.svg')
					);
					// @include OnOverTransition();
				}
				&[active='on']:hover {
					@include SetBgImage(
						url('../assets/img/ic-closed-input-h.svg')
					);
				}
			}
		}
		.line {
			width: 100%;
			height: gREm(1);
			margin-top: gREm(6);
			background-color: #ededed;
			@include Transition(0.5s ease-in-out);

			&[active='on'] {
				background-color: #6c38ef;
				height: gREm(2);
			}
		}
		// .line:focus {
		// 	background-color: #6c38ef;

		// }
	}
	.password {
		margin-top: gREm(50);
		// margin-bottom:gREm(20);
		.input-box {
			@include FLEX(space-between, center);
			.field-input {
				width: gREm(420 - 20 - 10);
			}
			.icon {
				&[active='on'] {
					width: gREm(20);
					height: gREm(20);
					cursor: pointer;
					@include SetBgImage(
						url('../assets/img/ic-closed-input-n.svg')
					);
					// @include OnOverTransition();
				}
				&[active='on']:hover {
					@include SetBgImage(
						url('../assets/img/ic-closed-input-h.svg')
					);
				}
			}
		}
	}

	.remember-me {
		margin-top: gREm(16);
		width: 100%;
		max-width: gREm(324);
		height: auto;
		@include FLEX(flex-end, center);
		.check-box {
			width: gREm(120);
			@include FLEX(flex-start, center);

			.checks {
				@include FLEX(center, center);
				width: gREm(19);
				height: gREm(19);
				cursor: pointer;
				@include SetBgImage(url('../assets/img/checkbox-n.svg'));
				// border-radius: 20px;
				// border:none;
				margin-right: gREm(8);
			}
			.remember {
				@include FLEX(flex-start, center);
				width: gREm(91);
				height: gREm(18);
				cursor: pointer;
				@include Set-Font($AppFont, gREm(14), gREm(24), #6a6a6a);
			}
			&[active='on'] {
				@include FLEX(flex-start, center);
				.checks {
					@include SetBgImage(url('../assets/img/checkbox-s.svg'));
					margin-right: gREm(8);
				}
			}
		}
		.forgot-msg {
			margin-left: gREm(130);
			.text {
				@include Set-Font($AppFont, gREm(14), gREm(24), #6c38ef);
			}
			.forgot-id,
			.forgot-pwd {
				cursor: pointer;
				&:hover {
					text-decoration-line: underline;
				}
			}
			.forgot-slash {
				padding-left: gREm(5);
				padding-right: gREm(5);
			}
		}
	}

	.loginbtn {
		margin-top: gREm(70);
		width: gREm(200);
		height: gREm(60);
		border-radius: 6px;
		@include Set-Font($AppFont, gREm(18), gREm(22), #ffffff);
		@include OnOverTransition();
	}

	.connectbtn {
		padding-left: gREm(75);
		margin-top: gREm(10);
		width: gREm(324);
		height: gREm(64);
		border-radius: 6px;
		font-family: $AppFont;
		font-size: gREm(18);
		// @include Set-Font($AppFont, gREm(18), gREm(22), unset);
		@include OnOverTransition();
		& .ic-image {
			margin-right: gREm(15);
			max-width: 28px;
			max-height: 28px;
		}
		&:hover {
			transform: translateY(-2px);
		}
	}

	.signup-box {
		margin-top: gREm(16);
		margin-bottom: gREm(50);
		width: 100%;
		max-width: gREm(324);
		text-align: left;
		.signup-box-wrap {
			width: 100%;
			height: auto;
			@include FLEX(flex-start, center);

			.notamember {
				width: auto;
				@include Set-Font($AppFont, gREm(14), gREm(22), #6a6a6a);
			}
			.signup-msg {
				width: auto;
				margin-left: gREm(7);
				cursor: pointer;
				@include Set-Font($AppFont, gREm(14), gREm(22), #6c38ef, 500);
				&:hover {
					text-decoration-line: underline;
				}
			}
		}
	}
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from,
.modal-leave-to {
	opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
	transition: opacity 0.5s ease;
}

// .modal-enter {
//   opacity: 0;
// }

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

@include media-max($media_small) {
	.modal-mask {
		.modal-wrapper {
			.modal-container {
				width: 100%;
				max-width: 90vw;
				padding: 0 gREm(20);
				max-height: 95vh;
				overflow-y: auto;

				.form {
					.or,
					.login-input,
					.login-pwd {
						width: 100%;
						font-family: $AppFont;

						input {
							width: 100%;
							font-family: $AppFont;
						}
					}
					.connectbtn {
						width: 100%;
						max-width: gREm(324);
						font-family: $AppFont;
						font-size: gREm(18);
					}
					.or {
						span {
							font-family: $AppFont;
						}
					}
				}
			}
		}
	}
}
</style>
