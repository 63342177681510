export function renderUnlockContent() {
  return `
  <p>All staked LANDs will be unlocked and returned to your account. Are you sure you want to unlock all staked LANDs?</p><br />
  <p>(You can:</p>
  <p>- leave the staked LANDs in the pool until the next campaign starts to continue staking them</p>
  <p>- or, unlock them now if you want to use them)</p>
  `
}
export function renderCampainNotYetContent() {
  return `
  <p>You can only stake your LANDs before the campaign starts.</p>`
}
export function renderNotLoginContent() {
  return `
  <p>You must connect your wallet before taking part in the Staking campaign.</p>
  <p>Please connect your wallet to continue.</p>
  `
}
export function renderSuccessContent() {
  return `
  <p>You have staked your LAND(s) successfully. The staked LANDs will be shown under the “Staked LANDs” section.</p><br />
  <p>Please note that you will be unable to unlock your LAND(s) during the Staking period. You can only unlock your LANDs after the campaign.</p>
  `
}
export function renderOnClickHarvestContent(data){
  return `
  <p>You got ${data} from the staking campaign!</p>
  <br />
    <p>
      The earned DVG has been transferred to your Dvision
      WORLD DVG balance.
    </p>
  `
}
export function renderOnUnStakeAllNftsSuccessContent() {
  return 'All of your staked LANDs have been unlocked and returned to your account.'
}
export function renderOnUnStakeNftsSuccessContent() {
  return 'The selected LAND has been unlocked and returned to your account.'
}
export function renderOnCheckItemUnStakeModalConfirmContent(name) {
  return `The selected LAND will be unlocked and returned to your account. Are you sure you want to unlock Dvision LAND: ${name}?`
}
export function renderSwitchNftContent(){
  return 'All changes made and all of your selections in the current screen will be lost if you switch to another LAND type. Proceed?'
}
export function renderStakingRewardsClaimed() {
  return `
  <p>The listed rewards/boxes have been transferred to your wallet.</p> 
  <br />
  <p>You can check your rewards and use them or reveal their content in the “My NFTs” section.</p>`
}

export function renderStakingAddedLandSuccess() {
  return `
  <p>You have staked your LAND(s) successfully. The staked LANDs will be shown under the “Staked LANDs” section.</p>
  <br />
  <p>Please note that you will be unable to unlock your LAND(s) during the Staking period. You can only unlock your LANDs after the campaign.</p>`
}