import AppConfig from '../App.Config'
export const INFURA_ID = '14ff3a7ed1484486aac3e5573bcae20d'
export const DEFAULT_ETH_JSONRPC_URL = `https://mainnet.infura.io/v3/${INFURA_ID}`
import store from '../store'

export function toFixedDecimal(val, decimals = 18) {
	val = String(val)
	const split = val.split('.')
	if (split.length > 1) {
		if (decimals) {
			return split[0] + '.' + split[1].substr(0, decimals)
		} else {
			return split[0]
		}
	} else {
		return val
	}
}
export const string2Balance = (str, decimals = 18) => {
	if (!str) return ''

	const arrStr = String(str).split('.')
	const start = arrStr[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

	const strAfter = (arrStr[1] || '')?.slice(0, decimals)
	const end = decimals
		? (strAfter || '0').padEnd(decimals, '0')
		: strAfter || ''

	return start + (decimals === 0 ? end : '.' + end)
}
export function checkErrorMessage(error) {
	const errCodes = [4001, -32603, -32602, 3001, DENIED_TRANSACTION]
	if (errCodes.includes(error.code) || errCodes.includes(error.message)) {
		return error.message
	}
}
export function checkWalletAddr(curAddr, curUser) {
	if (curAddr?.toLowerCase() === curUser?.toLowerCase()) return true
	else return false
}
export function checkNetwork(currentNetwork) {
	const networkBSC = store.getters.getBscAddr.Network
	const networkPoygon = store.getters.getPolygonAddr.Network
	const networkETH = store.getters.getEthAddr.Network
	if (
		currentNetwork !== networkBSC &&
		currentNetwork !== networkPoygon &&
		currentNetwork !== networkETH
	)
		return false
	else return true
}
export function checkProviderWallet(name) {
	if (!window.ethereum?.providers) {
		return
	}
	let provider = ''
	switch (name) {
		case METAMASK:
			provider = window.ethereum.providers.find(
				({ isMetaMask }) => isMetaMask
			)
			break
		case COINBASE:
			provider = window.ethereum.providers.find(
				({ isCoinbaseWallet }) => isCoinbaseWallet
			)
			break
	}

	if (provider) {
		window.ethereum.setSelectedProvider(provider)
		return provider
	}
}
export function formatChainId(chainId) {
	let network = chainId.toString()
	switch (chainId) {
		case ETH_CHAIN_ID:
		case ETH_CHAIN_ID.toString():
			network = '0x1'; // 메인
			break
		case ETH_ROP_CHAIN_ID:
		case ETH_ROP_CHAIN_ID.toString():
			network = '0x3'; // 미사용
			break
		case ETH_RINK_CHAIN_ID:
		case ETH_RINK_CHAIN_ID.toString():
			network = '0x4'; // 미사용
		case ETH_GOERLI_CHAIN_ID:
		case ETH_GOERLI_CHAIN_ID.toString():
			network = '0x5'; // 테스트넷
			break
		case BSC_CHAIN_ID:
		case BSC_CHAIN_ID.toString():
			network = '0x38'; // 메인넷
			break
		case BSC_TEST_CHAIN_ID:
		case BSC_TEST_CHAIN_ID.toString():
			network = '0x61'; // 테스트넷
			break
		case MATIC_CHAIN_ID:
		case MATIC_CHAIN_ID.toString():
			network = '0x89';
			break
		case MATIC_TEST_CHAIN_ID:
		case MATIC_TEST_CHAIN_ID.toString():
			network = '0x13881';
			break
	}
	return network
}
export function fromHexToChainId(network) {
	let chainId = network
	switch (network) {
		case '0x4':
			chainId = 4 //testnet
			break
		case '0x5':
			chainId = 5 //testnet
			break
		case '0x61':
			chainId = 97 //testnet
			break
		case '0x13881':
			chainId = 80001 //testnet
			break
	}
	return Number(chainId)
}

export function renderNetworkName(chainId) {
	let network = ''
	if (chainId) {
		switch (chainId.toString()) {
			case '0x1':
			case '1':
			case '0x4':
			case '4':
			case '0x3':
			case '3':
			case '0x5':
			case '5':
				network = 'ETH'
				break
			case '0x38':
			case '56':
			case '0x61':
			case '97':
				network = 'BSC'
				break
			case '0x89':
			case '137':
			case '0x13881':
			case '80001':
				network = 'POL'
				break
		}
	}
	return network
}

export function networkToChainId(network) {
	if(network.startsWith("0x")) {
		return parseInt(network, "16");
	}
	if(typeof network == "string") {
		return parseInt(network);
	}
	return network;
}
export const BSC_STAKING_ADDRESS = '0x4b437fd847Ac1b248eA419c13e62f08dC435A388'
export const ETH_STAKING_ADDRESS = '0x58f22dE77E00fd3836dD6877fdAE8462CC5Ebefc'
export const MATIC_STAKING_ADDRESS =
	'0xAE03C288f920703eba835C94763A93E8EA53c2C9'
export const BSC_RPC_ENDPOINT =
	'https://data-seed-prebsc-1-s1.binance.org:8545/'
export const ETH_RPC_ENDPOINT =
	'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
export const MATIC_RPC_ENDPOINT = 'https://rpc-mumbai.maticvigil.com/'
export const BSC_RPC_ENDPOINT_PROD = 'https://bsc-dataseed.binance.org/'
export const ETH_RPC_ENDPOINT_PROD =
	'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
export const MATIC_RPC_ENDPOINT_PROD = 'https://polygon-rpc.com/'
export const BSC_ADDRESS_721 = '0xD41eddEdB1891B626FADD17B328e14077c8248Cb'
export const BSC_ADDRESS_1155 = '0x3a0792d301a40eBcd9199431b00AD26603b7cdc4'
export const ETH_ADDRESS_721 = '0x13b03495467f7CfB7237cbC5784faDA36bf23848'
export const ETH_ADDRESS_1155 = '0x94d0D2FFE6d5Ab4eDd1d13Ca748cBC13210936dA'
export const MATIC_ADDRESS_721 = '0x9A42796215D1220381988DA2f1Ccc5456399b51d'
export const MATIC_ADDRESS_1155 = '0xc03c9c5385bEe279fE5b485985c7e92a1AfbaBEd'
export const BSC_PROXY_ADDRESS = '0xbbb1471C1bC1963A72a21F86180c41685797Db02'
export const BRIDGE_WALLETCONNECT = 'https://bridge.walletconnect.org'
export const ETH_CHAIN_ID = 1
export const ETH_ROP_CHAIN_ID = 3
export const ETH_RINK_CHAIN_ID = 4
export const ETH_GOERLI_CHAIN_ID = 5
export const BSC_CHAIN_ID = 56
export const BSC_TEST_CHAIN_ID = 97
export const MATIC_CHAIN_ID = 137
export const MATIC_TEST_CHAIN_ID = 80001
export const VALUE_LOGIN =
	'7cad118dfd6aade5cac88ab0656d82855fdc9028f4247e12430952d1b8085ed5'
export const METAMASK = 'METAMASK'
export const COINBASE = 'COINBASE'
export const FORTMATIC = 'FORTMATIC'
export const WALLETCONNECT = 'WALLETCONNECT'
export const BITSKI = 'BITSKI'
export const CLOVER = 'CLOVER'
export const GOOGLE = 'GOOGLE'
export const FACEBOOK = 'FACEBOOK'
export const DENIED_TRANSACTION = 'User rejected the transaction'
export const USER_DECLINED = 'The user has declined this request'
export const USER_CANCELED = 'The user cancelled this transaction'
export const OUT_OF_GAS = `You don't have enough native coins to make this transaction.`
export const ADDRESS_METAMASK = 'addressMetamask'

export const REWARD_TABLE_1 = [
	{
		land: '1x1',
		reward: 'Random Box A * 10',
	},
	{
		land: '1x1P',
		reward: 'Random Box B * 15',
	},
	{
		land: '2x1',
		reward: 'Random Box C * 10',
	},
	{
		land: '2x1P',
		reward: 'Random Box D * 15',
	},
	{
		land: '2x2',
		reward: 'Random Box E * 10',
	},
	{
		land: '2x2P',
		reward: 'Random Box F * 15',
	},
	{
		land: '3x3',
		reward: 'Random Box G * 10',
	},
]
export const REWARD_TABLE_2 = [
	{
		land: '1x1',
		reward: 'Random Box A * 10, Building Box A * 1',
	},
	{
		land: '1x1P',
		reward: 'Random Box B * 15, Building Box A * 1',
	},
	{
		land: '2x1',
		reward: 'Random Box C * 10, Building Box A * 1',
	},
	{
		land: '2x1P',
		reward: 'Random Box D * 15, Building Box A * 1',
	},
	{
		land: '2x2',
		reward: 'Random Box E * 10, Building Box A * 1',
	},
	{
		land: '2x2P',
		reward: 'Random Box F * 15, Building Box A * 1',
	},
	{
		land: '3x3',
		reward: 'Random Box G * 10, Building Box A * 1',
	},
]
export const REWARD_TABLE_3 = [
	{
		land: '1x1',
		reward: 'Random Box A * 10, Building Box B * 1',
	},
	{
		land: '1x1P',
		reward: 'Random Box B * 15, Building Box B * 1',
	},
	{
		land: '2x1',
		reward: 'Random Box C * 10, Building Box B * 1',
	},
	{
		land: '2x1P',
		reward: 'Random Box D * 15, Building Box B * 1',
	},
	{
		land: '2x2',
		reward: 'Random Box E * 10, Building Box B * 1',
	},
	{
		land: '2x2P',
		reward: 'Random Box F * 15, Building Box B * 1',
	},
	{
		land: '3x3',
		reward: 'Random Box G * 10, Building Box B * 1',
	},
]

export const LAND_CODE = {
	SEOUL: 'SeoulA',
	NEWYORK: 'Newyork',
	LONDON: 'London',
	TOKYO: 'Tokyo',
}

export const TYPE_ADDRESS_CONTRACT = {
	NORMAL: 'normal',
	ADDRESS_3_LAND: '3rd',
}

export const listLandCode = [
	{
		name: 'SeoulA',
		id: 'gangnam',
		type: 'normal',
		network: 'BSC',
	},
	{
		name: 'Newyork',
		id: 'newyork',
		type: 'normal',
		network: 'BSC',
	},
	{
		name: 'London',
		id: 'london',
		type: 'normal',
		network: 'POL',
	},
	{
		name: 'Tokyo',
		id: 'tokyo',
		type: 'normal',
		network: 'POL',
	},
	{
		name: 'Berlin',
		id: 'berlin',
		type: '3rd',
		network: 'BSC',
	},
	{
		name: 'São Paulo',
		id: 'saopaulo',
		type: '3rd',
		network: 'POL',
	},
]

export async function checkGasWithBalance(web3, gasNumber, currentAccount) {
	const priceOfGas = await web3.eth.getGasPrice()
	const balance = await web3.eth.getBalance(currentAccount)
	const totalPrice = gasNumber * priceOfGas
	console.log('price', +balance, totalPrice)
	return totalPrice > +balance
}

export function checkAddress(walletAddress) {
	const currentAddress = window.localStorage.getItem('addressMetamask')
	return currentAddress
		? currentAddress.toLowerCase() === walletAddress.toLowerCase()
		: true
}

export const renderContractAdd = (type, network) => {
	let wlt;
	switch(network) {
		case '0x1':
		case '0x3':
		case '0x4':
		case '0x5':
			wlt = [ store.getters.getEthAddr ];
			break;
		case '0x38':
		case '0x61':
			wlt = [ store.getters.getBscAddr, store.getters.get3rdLandBscAddr ];
			break;
		case '0x89':
		case '0x13881':
			wlt = [ store.getters.getPolygonAddr, store.getters.get3rdLandPolAddr ];
			break;
	}
	const arrAddress = []
	for (const key in wlt) {
		arrAddress.push(wlt[key])
	}
	return arrAddress.find(
		(ele) => ele.type === type && ele.Network === network
	)
}

export const SNAPSHOT_NAME = 'snapshot'
export const SNAPSHOT_VERSION = '0.1.4'

export const domain = {
	name: SNAPSHOT_NAME,
	version: SNAPSHOT_VERSION,
	// chainId: 1
}
export const vote2Types = {
	Vote: [
		{ name: 'from', type: 'address' },
		{ name: 'space', type: 'string' },
		{ name: 'timestamp', type: 'uint64' },
		{ name: 'proposal', type: 'bytes32' },
		{ name: 'choice', type: 'uint32' },
		{ name: 'metadata', type: 'string' },
	],
}
