<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">                    
					<div class="image"></div>
                    <div class="btn-top">
                        <button class="modal-close-btn" @click="closePopup"></button>
                    </div>
                    <div v-if="!soldout" class="btn-bottom">
                        <button class="btn-success" @click="goToLink"></button>                        
                    </div>					
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {
	FORTMATIC,
	BITSKI,
	WALLETCONNECT,
} from '@/features/Common.js'
import AppConfig from '@/App.Config.js'
import WalletAPI from '@/features/WalletAPI.js'
var wAPI = new WalletAPI();
var gConfig = AppConfig()

export default {
	components: {},
	mounted() {		
		// this.popType = authInfo.type;
	},
	computed: {},
	data() {
		return {
			soldout: false
		}
	},
	created() {
		console.log('obj', this.obj)
	},
	methods: {
        goToLink() {
            this.mxClose4thLandSaleModal()
        },
		closePopup() {
			this.mxClose4thLandSaleModal()            
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-mask {
	position: absolute;
	z-index: $Z-INDEX-LOGIN-POPUP;
	// margin-top: 224px;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.5s ease;

	.modal-wrapper {
		display: table-cell;
		vertical-align: middle;

		.modal-container {
			border-radius: 10px;
			max-width: 90vw;
            max-height: 81.25vw;
            width: gREm(917);
            height: gREm(828);
			margin: 0px auto;
			transition: all 0.3s ease;
			font-family: Montserrat, sans-serif;
			& .title {
				font-size: gREm(28);
				line-height: gREm(19);
				font-weight: 800;
				margin-bottom: gREm(30);
			}
			& .desc {
				font-family: Montserrat, sans-serif;
				font-size: gREm(16);
				font-style: normal;
				font-weight: 400;
				line-height: gREm(22);
				letter-spacing: 0em;
				text-align: left;
				margin-bottom: gREm(34);
			}
            & .btn-bottom {
                position: absolute;
                width: 100%;
                height: 7%;
                bottom: 1.25%;
                display: flex;
				align-items: center;
				justify-content: center;
                & .btn-success {                    
                    width: 40%;
                    height: 100%;
                    font-family: Montserrat, sans-serif;
                    font-size: gREm(16);
                    font-style: normal;
                    font-weight: 400;
                    line-height: gREm(30);
                    letter-spacing: 0em;
                    text-align: center;
                    border-radius: gREm(30);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    @include SetBgImage(
                        url('../assets/img/4thLandSale/main_error_btn.png')
                    );
                    &:hover {
                        @include SetBgImage(
                            url('../assets/img/4thLandSale/main_error_btn_hover.png')
                        );
                    }
                }
            }			
            .image {
				position: absolute;
                max-width: 90vw;
                max-height: 81.25vw;
				width: gREm(917);
				height: gREm(828); // gREm(475);
				bottom: 0px;
				@include SetBgImage(
					url('../assets/img/4thLandSale/main_error_popup.png')
				);
			}
			.soldout-image {
				position: absolute;
                max-width: 90vw;
                max-height: 50vw;
				width: gREm(1141);
				height: gREm(645); // gREm(475);
				bottom: 0px;
				@include SetBgImage(
					url('../assets/img/4thLandSale/main_soldout_popup.png')
				);
			}
            & .btn-top {
                position: absolute;
                width: 100%;
                height: 3%;
                top: 3%;
                display: flex;
				align-items: center;
				justify-content: center;
                .modal-close-btn {
                    position: absolute;
                    height: 100%;
                    width: 2.75%;
                    right: 3%;
                    cursor: pointer;
                    @include SetBgImage(
                        url('../assets/img/Close.svg')
                    );
                }
            }            
		}
	}
}

@include media-max($media_small) {
	.modal-mask {
		.modal-wrapper {
			.modal-container {
				max-width: 90vw;
				width: 100%;
				// margin: 0;

				.title {
					@include Set-Font(
						$AppFont,
						gREm(22),
						gREm(32),
						#ffffff,
						600
					);
					white-space: pre-wrap;
				}

				.desc {
					line-height: gREm(24);
				}

				.btn-success {
					font-size: gREm(14);
				}
			}
		}
	}
}
</style>
