
/* JnJ Tech. KOREA.
*/
/*----------------------------------------------------------------------*\
 J&J Technology Co. Confidential
 Licensed Materials - Property of J&J Technology Co. KOREA
 (C) COPYRIGHT J&J Technology, Co. 2011,2012  All rights reserved.

 Author   : Ja-Cheon Yoon
 Date     : 2015-04-29
 Version  : 1.0
 Purpose  : Utils of J&J Technology

 Ja-Cheon Yoon, Ph.D.        email: j c y o o n @ j n j t . c o . k r
\*----------------------------------------------------------------------*/
import axios from 'axios'

import AppConfig from '@/App.Config.js'
var gConfig = AppConfig();

export default function Utils() {

	var lv_checkDuplicateIds = [];

	return {
		getUUID(){
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
				return v.toString(16);
			});
		},
		E(str) {
			return document.getElementById(str);
		},
		Q(str) {
			return document.querySelector(str);
		},
		Qo(obj,str) {
			if(!obj) return null;
			var rv = obj.querySelector(str);
			// delete obj;
			return rv;
		},
		Qs(str) {
			return document.querySelectorAll(str);
		},
		Qos(obj,str) {
			if(!obj) return null;
			var rv = obj.querySelectorAll(str);
			// delete obj;
			return rv;
		},
		getAttr(obj,attr) {
			if(!obj) return null;
			if(typeof obj.getAttribute != "function") return null;
			return obj.getAttribute(attr);
		},
		setAttribute (obj,attrName, attrValue) {
			if(!obj) return null;
			if(typeof obj.getAttribute != "function") return null;
			obj.setAttribute(attrName,attrValue);
		},
		setAttr(o, attrs) {
			if(!o) return false;
			if(o && o.length > 0) {
				for(var i=0; i<o.length; i++) {
					for( key in attrs) {
						var v = _U.getAttr(o[i],key);
						if(v != attrs[key]) {
							_U.setAttribute(o[i], key, attrs[key]);
						}
					}
				}
			}
			else {
				for( var key in attrs) {
					var v = _U.getAttr(o,key);
					if(v != attrs[key]) {
						_U.setAttribute(o, key, attrs[key]);
					}
				}
			}
			return true;
		},
		isNumber(v) {
			if(typeof v == 'number') return true;
			return false;
		},
		getNumber(str) {
			var val = str;  var temp = "";  var num = "";
			for(var i=0; i<val.length; i++) {
				temp = val.charAt(i);
				if(temp >= "0" && temp <= "9") num += temp;
			}
			return num;
		},
		addComma(v) {
			if(!v) return '0';

			var val, decimal;
			decimal='';
			var str = (typeof v == 'string' ? v : v.toString());

			if(str.indexOf(".") >= 0) {
				val = str.substring(0,str.indexOf("."));
				decimal = str.substring(str.indexOf("."));
			}else{
				val = str;
			}

			val = this.getNumber(val);

			if(val.length <= 3)
				return val+decimal;

			var loop = Math.ceil(val.length / 3);
			var offset = val.length % 3;
			if(offset==0) offset = 3;
			var ret = val.substring(0, offset);
			for(var i=1;i<loop;i++) {
				ret += "," + val.substring(offset, offset+3);
				offset += 3;
			}

			return ret+decimal;
		},
		isDefined(o,v) {
			if(!o) return false;
			if(typeof o[v] == 'undefined' || o[v] == null) return false;
			else return true;
		},
		getIfDefined(o, arr) {

			if(this.isArray(arr) && arr.length > 0) {
				var found = true;
				var obj = o;
				for(var i=0; i<arr.length; i++) {
					var nn = arr[i];
					if(this.isDefined(obj, nn)) {
						obj = obj[nn];
					}else{
						found = false;
						break;
					}
				}
				return found == true ? obj : null; // last value
			}else{
				return this.isDefined(o,arr) ? o[arr] : null;
			}
		},
		isArray(o,v) {
			if(v) {
				return ( this.isDefined(o,v) && this.isDefined(o[v],'join') ? true : false );
			}else{
				return this.isDefined(o,'join');
			}
		},

		getTime() {
			if(typeof Date.now == 'function') {
				return Date.now();
			}else{
				return new Date().getTime();
			}
		},

		getCapitalChar(str) {
			if(!str) return '';
			var ch = str.substr(0,1);
			if(ch) {
				return ch.toUpperCase();
			}
			return '';
		},

		getCreateTime(str) {
			if(!str) return '';
			var ch = str.substr(0,10);
			if(ch) {
				return ch.toUpperCase();
			}
			return '';
		},

		encodeUrl (str) {
			if(!str) return '';
			return encodeURIComponent(str).replace(/'/g,"%27").replace(/"/g,"%22");
		},
		decodeUrl(str) {
			if(!str) return '';
			return decodeURIComponent(str.replace(/\+/g,  " "));
		},

		getFormParams(data) {
			var params = new URLSearchParams();
			for(var key in data) {
				params.append(key, data[key]);
			}
			return params;
		},

		callPost(J) {
			var data = gConfig.getPostData(J.data);
			var params =(_U.isDefined(J, 'dataType') && J.dataType == 'application/json') ? data :_U.getFormParams(data);
			var options = {
				headers: { "Content-Type": (_U.isDefined(J,'dataType') ? J.dataType : "application/x-www-form-urlencoded") }
			};
			axios.post(J.url, params, options )
			.then((resp)=>{
				J.callback(resp);
			});
		},

		callGet(J) {
			axios.get(J.url).
			then((resp) => {
				J.callback(resp);
			})
		},

		callSMS(data,callbackSMS) {

			console.log("[Utils] callSMS()-> req ", data);

			_U.callPost({
				url:gConfig.sms_url,
				data: data,
				callback: (resp) =>{
					callbackSMS(resp);
				}
			});

		},

		callEmail(data,callbackEmail) {

			console.log("[Utils] callEmail()-> req ", data);

			_U.callPost({
				url:gConfig.email_url,
				data: data,
				callback: (resp) =>{
					callbackEmail(resp);
				}
			});

		},

		checkCert(data,callbackCert) {
			_U.callPost({
				url:gConfig.check_cert,
				data: data,
				callback: (resp) =>{
					callbackCert(resp);
				}
			});
		},

		getObserver() {
			// usage : observer01 = _U.getObserver();
			// observer01.observe(_U.Q('.aaa'));
			// observer01.unobserve(_U.Q('.aaa'))
			// observer01.disconnect()// 모두 중지
			// intersection (뷰에 object가 보이는지 판단)
			// https://heropy.blog/2019/10/27/intersection-observer/
			// https://coolcssanimation.com/how-to-trigger-a-css-animation-on-scroll/
			return new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						_U.setAttr(entry.target,{isdisplayed:"on"});
						return; // if we added the class, exit the function
					}
					_U.setAttr(entry.target,{isdisplayed:"off"});
				});
			});
		},

		checkDuplicatedCall(J) {
			// console.error("=== TRACK === Util.checkDuplicatedCall() ", J);
			if(typeof J == 'undefined') J = {id:'jnjt',interval:500};
			if(!J || !J.id) return false;

			if(!lv_checkDuplicateIds[J.id]) {
				lv_checkDuplicateIds[J.id] = (typeof J.t =='undefined' ?  _U.getTime() : J.t);
				return false;
			}

			var now = (typeof J.t =='undefined' ?  _U.getTime() : J.t);
			if(now - lv_checkDuplicateIds[J.id] <= J.interval) {
				return true;
			}else{
				lv_checkDuplicateIds[J.id] = now;
				return false;
			}
		},

		// land info initialize - JHK
		getInitializeData(callback) {
			Promise.allSettled([				
				new Promise((resolve, reject) => this.callLandInfo(gConfig.getServerUrl("GetLandData"), "seoul", (data) => { resolve({"gangnam" : data}); })),
				new Promise((resolve, reject) => this.callLandInfo(gConfig.getServerUrl("GetLandData"), "newyork", (data) => { resolve({"newyork" : data}); })),
				new Promise((resolve, reject) => this.callLandInfo(gConfig.getServerUrl("GetLandData"), "tokyo", (data) => { resolve({"tokyo" : data}); })),
				new Promise((resolve, reject) => this.callLandInfo(gConfig.getServerUrl("GetLandData"), "london", (data) => { resolve({"london" : data}); })),
				new Promise((resolve, reject) => this.callLandInfo(gConfig.getServerUrl("GetLandData"), "berlin", (data) => { resolve({"berlin" : data}); })),
				new Promise((resolve, reject) => this.callLandInfo(gConfig.getServerUrl("GetLandData"), "saopaulo", (data) => { resolve({"saopaulo" : data}); })),
				new Promise((resolve, reject) => this.callLandInfo(gConfig.getServerUrl("GetLandData"), "hongkong", (data) => { resolve({"hongkong" : data}); })),
				new Promise((resolve, reject) => this.callLandInfo(gConfig.getServerUrl("GetLandData"), "amsterdam", (data) => { resolve({"amsterdam" : data}); }))
			]).then(result => {
				var DVILand = {
					"gangnam" : {},
					"newyork" : {},
					"london" : {},
					"tokyo" : {},
					"berlin" : {},
					"saopaulo" : {},
					"hongkong" : {},
					"amsterdam" : {}
				};

				for(var landInfo of result) {
					var info = landInfo.value;
					var value = info[Object.keys(info)[0]];

					DVILand[Object.keys(info)[0]] = value;
				}

				this.callGetData(gConfig.getCdnUrl('Item.json'), (itemResp) => {
					var itemData = {};

					for(var i = 0;i < itemResp.data.length;i++) {
						itemData[itemResp.data[i].itemIdx] = itemResp.data[i];
					}

					this.callGetData(gConfig.getCdnUrl('Language.json'), (languageResp) => {
						var languageData = {};

						for(var j = 0;j < languageResp.data.length;j++) {
							languageData[languageResp.data[j].code] = languageResp.data[j];
						}
						
						this.callConfigData((config) => { 
							callback(DVILand, config, itemData, languageData);
						});				
					});
				});
			}).catch(result => {
				// error check - JHK
			});
		},

		callGetData(url, callback) {
			this.callGet({
				url: url,
				callback: (resp) => {
				  callback(resp);
				}}
			  );
		},

		callConfigData(callback) {
			this.callPost({
				url: gConfig.getServerUrl('GetWebConfig'),
				data: "",
				callback: (resp) => {
				  var configData = {};

				  for(var config of resp.data) {
					  if(config.name.includes("addr") || config.name == "purchaseInfo") {
						configData[config.name] = JSON.parse(config.value);
					  }
					  else {
					  	configData[config.name] = config.value;
					  }
				  }

				  callback(configData);
				}}
			  );
		},

		callLandInfo(url, data, callback) {
			this.callPost({
				url: url,
				data: {"Land": data},
				callback: (resp) => {
					callback(resp.data);
			}});
		},

		// 4차랜드 뽑기 후 뽑은 토큰 데이터 받아오기
		call4thLandByTokenId(token_id, callback) {
			this.callPost({
				url: gConfig.getServerUrl('Get4thLandData'),
				data: { token_id },
				callback: (resp) => {
				  var configData = {};

				  callback(resp.data);
				}}
			  );
		},

		/**
		 * 로그 저장
		 * keyIdx : String, 
		 * keyValue : Number, 
		 * landIdx : Number, 
		 * floor : Number,
		 * currencyType : String, 
		 * currencyValue : Number, 
		 * totalCurrency : Number,
		 * message : String,
		 * action : Number
		 */
		callSaveLog(data) {
			var userInfo = this.getCookie("userInfo");
			if(userInfo != undefined)
				userInfo = JSON.parse(userInfo);
			const accountId = userInfo.accountId || '';
			const	authToken = userInfo.authToken || '';
			console.log("callSaveLog", {...data, accountId, authToken});
			this.callPost({
				url: gConfig.save_log,
				data: {...data, accountId, authToken},
				callback: (resp) => {
				  
				}});
		},

		callRemainLandCount(data, callback) {
			this.callPost({
				url: gConfig.remain_land_count,
				data,
				callback: (resp) => {
				  callback(resp.data);
				}});
		},

		getCookie(cookieName){
			var cookieValue=null;
			if(document.cookie){
					var array=document.cookie.split((escape(cookieName)+'='));
					if(array.length >= 2){
							var arraySub=array[1].split(';');
							cookieValue=unescape(arraySub[0]);
					}
			}
			return cookieValue;
		},
		// land info initialize - JHK

		async getFeePerGas(network) {
			if(["0x89", "0x13881"].includes(network)) {
				let maxFeePerGas = ethers.BigNumber.from(40000000000) // fallback to 40 gwei
				let maxPriorityFeePerGas = ethers.BigNumber.from(40000000000) // fallback to 40 gwei
				try {
					const { data } = await axios({
							method: 'get',
							url: gConfig.isProd
							? 'https://gasstation-mainnet.matic.network/v2'
							: 'https://gasstation-mumbai.matic.today/v2',
					})
					maxFeePerGas = ethers.utils.parseUnits(
							Math.ceil(data.fast.maxFee) + '',
							'gwei'
					)
					maxPriorityFeePerGas = ethers.utils.parseUnits(
							Math.ceil(data.fast.maxPriorityFee) + '',
							'gwei'
					)
				} catch {
						// ignore
				}
				return {
					maxFeePerGas,
					maxPriorityFeePerGas,
				}
			}
			return {
			}
		},

		getItemGrade(item_grade) {
			switch(item_grade) {
				case "3":
				default:
					return "Common";
				case "4":
					return "Advanced";
				case "5":
					return "Rare";
				case "6":
					return "Epic";
				case "7":
					return "Legendary";
				case "8":
					return "Mythic";
			}
		}
	}
}
