import WalletLink from 'walletlink'
import WalletConnectProvider from '@walletconnect/web3-provider'
import {
	BSC_RPC_ENDPOINT,
	ETH_RPC_ENDPOINT,
	MATIC_RPC_ENDPOINT,
	FORTMATIC,
	WALLETCONNECT,
	BITSKI,
	CLOVER,
	fromHexToChainId,
} from './Common'
import Fortmatic from 'fortmatic'
import { Bitski } from 'bitski'
import Web3 from 'web3'
import { Web3Provider } from '@ethersproject/providers'
import AppConfig from '@/App.Config.js'
var gConfig = AppConfig()

export const walletLink = new WalletLink({
	appName: 'Division Network',
	appLogoUrl: 'https://dvision.app/img/NV-logo.ae27f28f.svg',
	darkMode: false,
	supportedChainIds: [1, 4, 56, 97, 137, 80001],
})
export const coinbaseProvider = walletLink.makeWeb3Provider()
export const fortmaticProvider = new Fortmatic(
	gConfig.isProd
		? gConfig.FORTMATIC_API_KEY_PRODUCT
		: gConfig.FORTMATIC_API_KEY_DEV
)
export const walletConnectProvider = new WalletConnectProvider({
	rpc: {
		1: 'https://mainnet.infura.io/v3/d855ce2e93534d94b7c1682051c81d35',
		3: 'https://ropsten.mycustomnode.com',
		5: 'https://goerli.infura.io/v3/d855ce2e93534d94b7c1682051c81d35',
		11155111: 'https://sepolia.infura.io/v3/d855ce2e93534d94b7c1682051c81d35',
		97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
		56: 'https://bsc-dataseed1.defibit.io/',
		137: 'https://polygon-rpc.com/',
		80001: MATIC_RPC_ENDPOINT,
	},
})

export var shouldForceModalOpen = false;
walletConnectProvider.wc.on('modal_closed', () => {
	shouldForceModalOpen = true
	console.log('QR Code Modal closed')
})

export const bitski = new Bitski(
	gConfig.isProd
		? gConfig.BITSKI_CLIENT_ID_PRODUCT
		: gConfig.BITSKI_CLIENT_ID_DEV,
	`${window.location.origin}/callback.html`
)

export function getContractConnect(loginBy, abi, address_ct, network, chainId) {
	let web3
	let networkOptions = {
		rpcUrl: network,
		chainId: chainId,
	}
	switch (loginBy) {
		case FORTMATIC:
			// const fm = new Fortmatic(FORTMATIC_API_KEY)
			web3 = new Web3(fortmaticProvider.getProvider())
			break
		case WALLETCONNECT:
			walletConnectProvider.enable()
			web3 = new Web3(walletConnectProvider)
			break
		case BITSKI:
			networkOptions.chainId = fromHexToChainId(chainId)
			const bitskiProvider = bitski.getProvider({
				network: networkOptions,
			})
			web3 = new Web3(bitskiProvider)
			break
		case CLOVER:
			web3 = new Web3(window.clover)
			break
		default:
			//Metamask | coinbase
			web3 = new Web3(Web3.givenProvider)
			break
	}
	const contractConn = new web3.eth.Contract(abi, address_ct)
	return contractConn
}
export function getProvider(loginBy, network, chainId) {
	let web3 = new Web3()
	let networkOptions = {
		rpcUrl: network,
		chainId: chainId,
	}
	switch (loginBy) {
		case FORTMATIC:
			// const fm = new Fortmatic(FORTMATIC_API_KEY)
			web3 = new Web3Provider(fortmaticProvider.getProvider())
			break
		case WALLETCONNECT:
			walletConnectProvider.enable()
			web3 = new Web3Provider(walletConnectProvider)
			break
		case BITSKI:
			networkOptions.chainId = fromHexToChainId(chainId)
			const bitskiProvider = bitski.getProvider({
				network: networkOptions,
			})		
			web3 = new Web3Provider(bitskiProvider)
			//web3 = bitskiProvider
			console.log('Web3Provider', web3);
			break
		case CLOVER:
			web3 = new Web3(window.clover)
			break
		default:
			//Metamask | coinbase
			web3 = new Web3Provider(Web3.givenProvider)
			break
	}
	return web3
}

export function getWeb3(loginBy, network, chainId) {
	let web3
	switch (loginBy) {
		case FORTMATIC:
			web3 = new Web3(fortmaticProvider.getProvider())
			break
		case WALLETCONNECT:
			walletConnectProvider.enable()
			web3 = new Web3(walletConnectProvider)
			break
		case BITSKI:
			web3 = new Web3(getBitskiProvider(network, chainId))
			break
		case CLOVER:
			web3 = new Web3(window.clover)
			break
		default:
			web3 = new Web3(Web3.givenProvider)
			break
	}
	return web3
}

const getBitskiProvider = (network, chainId) => {
	let networkOptions = {
		rpcUrl: network,
		chainId: fromHexToChainId(chainId),
	}
	const bitskiProvider = bitski.getProvider({
		network: networkOptions,
	})
	return bitskiProvider
}
