<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="form">
						<BaseButton
							type="button"
							class="closebtn"
							@click="closePopup"
						>
						</BaseButton>

						<div class="title">
							{{ $t('popup.report.title') }}
						</div>

						<div class="field-set-box">
							<div class="field-set">
								<div class="label-box">
									<label class="label">
										{{ report.id.label }}
									</label>
								</div>

								<div class="field-box-wrap">
									<div class="field-box">
										{{ formatName(report.id.value) }}
									</div>
								</div>
							</div>
							<textarea
								:name="reason.name"
								v-model="reason.content"
								:placeholder="reason.placeholder"
								@focusin="valuefocus = true"
								@focusout="valuefocus = false"
								:active="valuefocus ? 'on' : 'off'"
								maxlength="500"
							>
							</textarea>
						</div>

						<button
							class="submit-btn"
							@click="onSubmit"
							:disabled="reason.content.length === 0"
						>
							{{ $t('popup.report.btn-name') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { createReport } from '@/services/proposals.js'
import PopupInput from '@/components/PopupInput.vue'

export default {
	name: 'PopupReport',
	components: {
		PopupInput,
	},
	data() {
		return {
			report: {
				id: {
					name: 'id',
					disabled: true,
					checked: true,
					label: this.$t('signup.register.label-id'),
					placeholder: this.$t('signup.register.place-holder-id'),
					type: 'id',
					value: this.$store.state.userInfo.wallet_addr,
					maxlength: 20,
					minlength: 5,
				},
			},
			reason: {
				name: 'reason',
				placeholder: this.$t('popup.report.place-holder-reason'),
				content: '',
			},
			valuefocus: false,
		}
	},
	props: {},
	watch: {
		'reason.content'() {
			if (this.reason.content.charAt(0) === ' ') {
				this.reason.content = this.reason.content.replace(' ', '')
			}
			this.reason.content = this.reason.content.replaceAll('  ', ' ')
		},
	},
	methods: {
		closePopup() {
			this.mxCloseReportPopup()
		},
		formatName(name) {
			// let name = this.data.proposals_user ? this.data.proposals_user : ''
			let firstStr = name.substring(0, 5)
			let secondStr = name.substring(name.length - 4, name.length)
			return firstStr.concat('...', secondStr)
		},
		async onSubmit() {
			let payload = {
				userId: this.$store.state.userInfo.wallet_addr,
				content: this.reason.content,
				proposalId: this.$route.params.itemId,
			}
			this.mxShowLoading('inf')
			await createReport(payload)
				.then((res) => {
					this.mxCloseLoading()
					this.mxCloseReportPopup()
					this.mxShowToast('Report success!')
				})
				.catch((err) => {
					this.mxCloseLoading()
					this.mxShowToast('Report failed!')
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-mask {
	position: absolute;
	z-index: $Z-INDEX-CHECK-PASSWORD-POPUP;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.5s ease;

	.modal-wrapper {
		display: table-cell;
		vertical-align: middle;

		.modal-container {
			width: gREm(585);
			height: gREm(408);
			margin: 0px auto;
			background-color: #fff;
			border-radius: 20px;
			box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
			transition: all 0.3s ease;
			font-family: 'Montserrat';
		}
	}
}

.form {
	@include FLEXV(flex-start, center);
	width: 100%;
	height: 100%;
	padding-left: gREm(50);
	padding-right: gREm(50);

	.field-set-box {
		@include FLEXV(flex-start, center);
		margin-top: gREm(32);
		width: 100%;
		height: auto; //gREm(28px + 24px + 30 + 123 + 2);
		.field-set {
			display: flex;
			gap: gREm(10);
			width: 100%;
			height: auto;
			align-items: center;
			.label-box {
				.label {
					width: auto;
					font-weight: 600;
					font-size: 16px;
					line-height: 150%;
					color: #454348;
					font-family: 'Montserrat';
					font-style: normal;
				}
			}
			.field-box-wrap {
				width: 100%;
				.field-box {
					color: black;
					font-family: 'Montserrat';
					input {
						width: 100%;
						font-weight: 400;
						font-size: 14px;
						line-height: 150%;
						color: #454348;
						font-family: 'Montserrat';
						font-style: normal;
					}
				}
			}
		}
		textarea {
			color: black;
			border: 1px solid #efeeef;
			width: 100%;
			margin-top: gREm(8);
			padding: gREm(12) gREm(16);
			border-radius: gREm(8);
			height: gREm(172);
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			resize: none;
			&[active='on'] {
				border: 1px solid #6c38ef;
				box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
			}
		}
	}

	.closebtn {
		@include SetBgImage(url('../assets/img/close-popup.svg'));
		width: gREm(16);
		height: gREm(16);
		cursor: pointer;
		position: absolute;
		top: gREm(32);
		right: gREm(29);
		z-index: 10;
	}

	.title {
		margin-top: gREm(32);
		text-align: center;
		@include Set-Font($AppFont, gREm(24), gREm(29), #0d0c22, 600);
	}

	.submit-btn {
		font-family: 'Montserrat';
		margin: gREm(32) 0;
		padding: 8px 24px;
		border-radius: 6px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: #f9fafb;
		background: #6c38ef;
		cursor: pointer;
		&:hover {
			box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
			transform: translateY(gREm(-5));
			transition: 0.2s;
		}
		&:disabled {
			opacity: 0.6;
		}
	}
}

.modal-enter-from,
.modal-leave-to {
	opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
	transition: opacity 0.5s ease;
}

// .modal-enter {
//   opacity: 0;
// }

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

@include media-max($media_small) {
	.modal-mask {
		.modal-wrapper {
			.modal-container {
				width: 90vw;
				height: auto;

				.form {
					padding: 0 gREm(32);

					.field-set-box {
						margin-top: gREm(26);
					}

					.title {
						text-align: left;
						font-size: gREm(22);
						margin-top: gREm(24);
					}

					.submit-btn {
						margin-bottom: gREm(40);
						margin-top: gREm(32);
					}
				}
			}
		}
	}
}
</style>
