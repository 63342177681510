import { API_DOMAIN } from './config'
import axios from 'axios'
import { fromHexToChainId } from '@/features/Common.js'

export function createProposal(payload) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_DOMAIN}/api/proposals`, payload, {
				headers: {
					'Access-Control-Allow-Origin': '*',
				},
			})
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function getProposals(payload) {
	let params = { ...payload }
	if (localStorage.getItem('loginBy')) {
		params = {
			...payload,
			chainId: fromHexToChainId(localStorage.getItem('currentNetwork')),
		}
	}
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_DOMAIN}/api/proposals/condition`, {
				headers: {},
				params: {
					...params,
				},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function getVotePower(payload) {
	console.log("getVotePower");
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_DOMAIN}/api/votepower`, {
				headers: {},
				params: {
					...payload,
				},
			})
			.then((response) => {
				console.log('getVotePower', response)
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function getProposalByID(id) {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_DOMAIN}/api/proposals/${id}`, {
				headers: {},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function getCommentsByID(id) {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_DOMAIN}/api/comments/${id}`, {
				headers: {},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function createComment(payload) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_DOMAIN}/api/comments`, payload, {
				headers: {
					'Access-Control-Allow-Origin': '*',
				},
			})
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function getMyProposal(payload) {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_DOMAIN}/api/proposals`, {
				headers: {},
				params: {
					...payload,
					chainId: fromHexToChainId(
						localStorage.getItem('currentNetwork')
					),
				},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function deleteProposal(proposalId, user) {
	return new Promise((resolve, reject) => {
		axios
			.delete(
				`${API_DOMAIN}/api/proposals?proposalId=${proposalId}&user=${user}`,
				{
					headers: {},
				}
			)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function getVotes(data) {
	const endpoint = 'https://hub.snapshot.org/graphql'
	const headers = {
		'content-type': 'application/json',
		Authorization: '<token>',
	}
	const graphqlQuery = {
		operationName: 'Votes',
		query: `query Votes {
			votes(where: {proposal: "${data.proposals_snapshotId}"},orderBy: "vp", orderDirection: desc) {
				voter
				choice
				vp
				vp_by_strategy
			}
		}
		`,
		variables: {},
	}
	return new Promise((resolve, reject) => {
		axios({
			url: endpoint,
			method: 'post',
			headers: headers,
			data: graphqlQuery,
		})
			.then(async (response) => {
				// vp가 0인것들은 scores로 다시 vp를 받아온다
				const vpIsZero = [];
				response.data.data.votes.forEach(item => {
					if(item.vp == 0) {	
						vpIsZero.push(item.voter);
					}
				})

				if(vpIsZero.length > 0) {
					const params = {
						space: data.proposals_snapshotSpace,
						network: data.proposals_snapshotNetwork,
						snapshot: data.proposals_snapshotProposal,
						strategies: JSON.parse(data.proposals_strategies),
						addresses: vpIsZero
					}

					const vps = await getScores(params);
					const scores = vps.result.scores;

					scores.forEach(v => {
						const user = Object.keys(v)[0];
						const vp = v[user];
						const item = response.data.data.votes.find(item => item.voter == user);	
						if(item != undefined){
							item.vp = vp;
						}
					})
				}

				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function getScores(params) {

	return new Promise((resolve, reject) => {
		axios
			.post(`https://score.snapshot.org/api/scores`, {
				params,
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function getStrategies(proposalId) {
	const endpoint = 'https://hub.snapshot.org/graphql'
	const headers = {
		'content-type': 'application/json',
		Authorization: '<token>',
	}
	const graphqlQuery = {
		operationName: 'Proposals',
		query: `query Proposals {
			proposals(where: {id: "${proposalId}"}) {
				id
				space {
					id
				}
				network
				strategies {
					network
					params
				}
				snapshot
				scores
			}
		}
		
		`,
		variables: {},
	}

	return new Promise((resolve, reject) => {
		axios({
			url: endpoint,
			method: 'post',
			headers: headers,
			data: graphqlQuery,
		})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function createReport(payload) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_DOMAIN}/api/reports`, payload, {
				headers: {
					'Access-Control-Allow-Origin': '*',
				},
			})
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function getMyVotes(payload) {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_DOMAIN}/api/votes`, {
				headers: {
					'Access-Control-Allow-Origin': '*',
				},
				params: {
					...payload,
					chainId: fromHexToChainId(
						localStorage.getItem('currentNetwork')
					),
				},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function createMyVotes(proposalId, userId, score) {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${API_DOMAIN}/api/votes?proposalId=${proposalId}&userId=${userId}&score=${score}`,
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				}
			)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})
}
