const LogActionType = {
	WEB_MARKET_LOOKUP: 5000,                      // 마켓 조회 기능 사용에 대한 로그
	WEB_VISIT_MARKET_PAGE: 5001,                  // 마켓 페이지 방문
	WEB_DOWNLOAD_CLIENT: 5002,                    // 클라이언트 다운로드
	
	WEB_VISIT_LANDSALE_PAGE: 5100,                // 랜드 세일 페이지 방문자
	WEB_LANDSALE_PURCHASE_CLICK: 5101,            // 랜드 세일 구매 클릭
	WEB_LANDSALE_PURCHASE: 5102,                  // 구매자 수 및 구매 수량
	WEB_LANDSALE_REMAIN: 5103,                     // 잔여 랜드(수)

	WEB_EVENT_PAGE: 5200,                     // 이벤트 페이지 방문자
	WEB_EVENT_PURCHASE: 5201,				// 이벤트 구매 수
}

export default LogActionType;