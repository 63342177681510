import { createRouter, createWebHistory } from 'vue-router'
import LogType from '../features/LogType'

const routes = [
	{
		path: '/',
		name: '',
		redirect: () => {
			return '/en'
		},
	},
	{
		path: '/:lang',
		component: {
			template: '<router-view />',
		},
		redirect: (to) => ({
			path: '/' + to.params.lang + '/home',
		}),
		children: [
			{
				path: '',
				name: 'Home',
				component: () => import('@/views/Home.vue'),
			},
			{
				path: 'home',
				name: 'Home',
				component: () => import('@/views/Home.vue'),
			},
			{
				path: 'market',
				component: {
					template: '<router-view />',
				},
				name: 'Market',
				// redirect: (to) => ({
				// 	path: '/' + to.params.lang + '/market/bsc',
				// }),
				beforeEnter: (to, from, next) => {
					next('/' + to.params.lang + '/market/bsc');
					// _U.callSaveLog({
					// 	keyIdx : "", 
					// 	keyValue : 0, 
					// 	landIdx : 0, 
					// 	floor : 0,
					// 	currencyType : "", 
					// 	currencyValue : 0, 
					// 	totalCurrency : 0,
					// 	message : "",
					// 	action : LogType.WEB_MARKET_LOOKUP
					// })
				}
			},
			{
				path: 'market/:tab_page',
				name: 'Market-Page',
				props: true,
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Market.vue'),
				beforeEnter: (to, from, next) => {
					// console.log('[JNJT router/index.js] beforeEnter(),to, from,next : ',to, from,next);
					// var view = {name:to.name}
					if (
						_U.getIfDefined(from, [
							'params',
							'from',
							'params',
							'from',
						])
					) {
						from.params.from.params.from = null
					}
					to.params.from = from
					next()
					_U.callSaveLog({
						keyIdx : to.params.tab_page, 
						keyValue : 0, 
						landIdx : 0, 
						floor : 0,
						currencyType : "", 
						currencyValue : 0, 
						totalCurrency : 0,
						message : "",
						action : LogType.WEB_VISIT_MARKET_PAGE
					})
				},
			},
			{
				path: 'market/:tab_page/:itemId',
				name: 'Market-Detail',
				props: true,
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Market.vue'),
			},
			{
				path: 'market/:tab_page/:itemId/:index',
				name: 'Market-Detail-Index',
				props: true,
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Market.vue'),
				beforeEnter: (to, from, next) => {
					// console.log('[JNJT router/index.js] beforeEnter(),to, from,next : ',to, from,next);
					// var view = {name:to.name}
					if (
						_U.getIfDefined(from, [
							'params',
							'from',
							'params',
							'from',
						])
					) {
						from.params.from.params.from = null
					}
					to.params.from = from
					next()
				},
			},
			{
				path: 'studio',
				name: 'Studio',
				component: () => import('@/views/Studio.vue'),
			},
			{
				path: 'guide',
				component: {
					template: '<router-view />',
				},
				name: 'Guide',
				redirect: (to) => ({
					path: '/' + to.params.lang + '/guide/guide',
				}),
			},
			{
				path: 'guide/:page',
				name: 'Guide-Page',
				props: true,
				component: () => import('@/views/Guide.vue'),
			},
			{
				path: 'guide/:page/:wIdx',
				name: 'Guide-SubPage',
				props: true,
				component: () => import('@/views/Guide.vue'),
			},
			{
				path: 'event',
				name: 'Event',
				props: true,
				component: () => import('@/views/Event.vue'),
				beforeEnter: (to, from, next) => {					
					_U.callSaveLog({
						keyIdx : "event", 
						keyValue : 0, 
						landIdx : 0, 
						floor : 0,
						currencyType : "", 
						currencyValue : 0, 
						totalCurrency : 0,
						message : "",
						action : LogType.WEB_EVENT_PAGE
					})
					if (
						_U.getIfDefined(from, [
							'params',
							'from',
							'params',
							'from',
						])
					) {
						from.params.from.params.from = null
					}
					to.params.from = from
					next()
				}
			},
			{
				path: 'event/vote',
				component: {
					template: '<router-view />',
				},
				name: 'Event-Vote',
				props: true,
				redirect: (to) => ({
					path: '/' + to.params.lang + '/event',
				}),
				// component: () => import('@/views/Event.vue'),
			},
			{
				path: 'news',
				component: {
					template: '<router-view />',
				},
				name: 'News',
				redirect: (to) => ({
					path: '/' + to.params.lang + '/news/0',
				}),
			},
			{
				path: 'news/:page',
				name: 'News-Page',
				props: true,
				component: () => import('@/views/News.vue'),
			},
			{
				path: 'news-detail/:itemId',
				name: 'News-Detail',
				props: true,
				component: () => import('@/views/News.Detail.vue'),
			},
			{
				path: 'dao-detail/:itemId',
				name: 'Dao-Detail',
				// props: true,
				component: () => import('@/views/Dao.Detail.vue'),
			},
			{
				path: 'dao',
				component: {
					template: '<router-view />',
				},
				name: 'Dao',
				component: () => import('@/views/Dao.vue'),
			},
			{
				path: 'create-proposal/:category',
				name: 'CreateProposal',
				props: true,
				component: () => import('@/views/CreateProposal.vue'),
			},
			{
				path: 'voting-power/:address',
				name: 'Voting-Power',
				component: () => import('@/views/VotingPowerPage.vue'),
			},
			{
				path: 'transparency',
				name: 'Transparency',
				component: () => import('@/views/TransparencyPage.vue'),
			},
			{
				path: 'activity',
				name: 'Activity',
				component: () => import('@/views/ActivityPage.vue'),
			},
			{
				path: 'submit',
				name: 'SubmitPage',
				component: () => import('@/views/SubmitPage.vue'),
			},
			{
				path: 'catalyst',
				name: 'Catalyst',
				component: () => import('@/views/CatalystPage.vue'),
			},
			{
				path: 'signup',
				name: 'Signup',
				component: {
					template: '<router-view />',
				},
				redirect: (to) => ({
					// path: '/'+to.params.lang+'/signup/agree', // agree, register, confirm,
					path: '/' + to.params.lang + '/signup/register', // agree, register, confirm,
				}),
			},
			{
				path: 'signup/:page',
				name: 'Signup-Page',
				props: true,
				component: () => import('@/views/Signup.vue'),
			},
			{
				path: 'mypage',
				name: 'MyPage',
				component: {
					template: '<router-view />',
				},
				redirect: (to) => ({
					path: '/' + to.params.lang + '/mypage/profile', // profile inventory
				}),
			},
			{
				path: 'mypage/:page',
				name: 'MyPage-Page',
				props: true,
				component: () => import('@/views/MyPage.vue'),
			},
			{
				path: 'walletconnect/:type',
				name: 'Wallet-Connect',
				props: true,
				component: () => import('@/views/WalletConnectPage.vue'),
			},
			{
				path: 'approve',
				name: 'Approve',
				component: () => import('@/views/ApprovePage.vue'),
			},
			// {
			// 	path: 'mypage/:page/:wIdx',
			// 	name: 'mypage-SubPage',
			// 	props: true,
			// 	component: () =>
			// 		import('@/views/MyPage.vue'),
			// },
			// {
			// 	path: 'help',
			// 	name: 'Help',
			// 	component: () =>
			// 		import(/* webpackChunkName: "about" */ '@/views/Help.vue'),
			// },
		],
		beforeEnter: (to, from, next) => {
			const loginBy = window.localStorage.getItem('loginBy')
			if (to.name === 'MyPage-Page' && !loginBy) {
				next('/' + to.params.lang + '/home')
				return
			} else next()
			// var view = {name:to.name}
			// if(_U.getIfDefined(from,['params','jfrom','jfrom'])) {
			// 	from.params.jfrom.jfrom = null;
			// }
			// to.params.jfrom = from;
		},
		// beforeEnter: (to, from, next) => {
		// 	console.log('[JNJT router/index.js] beforeEnter(),to, from,next : ',to, from,next)
		// 	// view = {name:to.name, path:to.path, params:param}
		// 	var view = {name:'Home', path:to.path, params:to.param}
		// 	// if(!Utils.isContains(Env.CONFIG.language.supported,to.params['lang'])){
		// 	// 	if(to.params['lang'] === 'auto'){
		// 	// 		let param = to.params
		// 	// 		param['lang'] = Utils.getLanguage()
		// 	// 		view = {name:to.name, path:to.path.split(to.params['lang'],param['lang']),params:param}
		// 	// 	}else{
		// 	// 		view = {name:Env.RESOURCE.ROUTER.URL.NOT_FOUND}
		// 	// 		// view = {name:to.name, path:to.path, params:param}
		// 	// 	}
		// 	// }
		// 	next(view)
		// }
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router
