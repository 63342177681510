<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">                    
					<div class="image"></div>
                    <div class="btn-top">
                        <button class="modal-close-btn" @click="closePopup"></button>
                    </div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import AppConfig from '@/App.Config.js'
var gConfig = AppConfig()

export default {
	components: {},
	mounted() {},
	computed: {},
	data() {},
	created() {
		console.log('obj', this.obj)
	},
	methods: {        
		closePopup() {
			this.mxCloseTransactionErrorModal()            
		}
	},
}
</script>

<style lang="scss" scoped>
.modal-mask {
	position: absolute;
	z-index: $Z-INDEX-LOGIN-POPUP;
	// margin-top: 224px;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.5s ease;

	.modal-wrapper {
		display: table-cell;
		vertical-align: middle;

		.modal-container {
			border-radius: 10px;
			max-width: 90vw;
            max-height: 90vw;
            width: gREm(643);
            height: gREm(646);
			margin: 0px auto;
			transition: all 0.3s ease;
			font-family: Montserrat, sans-serif;	
            .image {
				position: absolute;
                max-width: 90vw;
                max-height: 90vw;
				width: gREm(643);
				height: gREm(646); // gREm(475);
				bottom: 0px;
				@include SetBgImage(
					url('../assets/img/4thLandSale/transaction_error_popup.png')
				);
			}
            & .btn-top {
                position: absolute;
                width: 100%;
                height: 4%;
                top: 4%;
                display: flex;
				align-items: center;
				justify-content: center;
                .modal-close-btn {
                    position: absolute;
                    height: 100%;
                    width: 4%;
                    right: 3%;
                    cursor: pointer;
                    @include SetBgImage(
                        url('../assets/img/Close.svg')
                    );
                }
            }            
		}
	}
}

@include media-max($media_small) {
	.modal-mask {
		.modal-wrapper {
			.modal-container {
				max-width: 90vw;
				width: 100%;
				// margin: 0;
            
			}
		}
	}
}
</style>
